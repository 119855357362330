<template>
  <div>
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 2000 585"
      style="enable-background: new 0 0 2000 585"
      xml:space="preserve"
      width="100vw"
    >
      <g id="img">
        <image
          style="overflow: visible; enable-background: new"
          width="2000"
          height="585"
          xlink:href="@/assets/img/Responsibly-Built-Current/house.jpg"
        ></image>
      </g>
      <g id="solar_hover" class="cursor-pointer" @mouseover="over = 'solar'" @mouseout="over = null">
        <rect
          id="raised_hover_4_"
          x="1295.9"
          y="157.4"
          class="st8"
          width="281.1"
          height="130.3"
        />
      </g>
      <g id="board_hover" class="cursor-pointer" @mouseover="over = 'board'" @mouseout="over = null">
        <rect
          id="raised_hover_1_"
          x="1397"
          y="310.3"
          class="st8"
          width="232.3"
          height="112.5"
        />
      </g>
      <g id="fsc_hover" class="cursor-pointer" @mouseover="over = 'fsc'" @mouseout="over = null">
        <rect
          id="raised_hover"
          x="1156.8"
          y="289.9"
          class="st8"
          width="168.5"
          height="132.9"
        />
      </g>
      <g id="raised_hover_2_" class="cursor-pointer" @mouseover="over = 'raised'" @mouseout="over = null">
        <rect
          id="raised_hover_6_"
          x="496.3"
          y="344.6"
          class="st8"
          width="281.1"
          height="130.3"
        />
      </g>
      <g id="_x39_9_hover" class="cursor-pointer" @mouseover="over = 'x39'" @mouseout="over = null">
        <rect
          id="raised_hover_3_"
          x="308.3"
          y="156.4"
          class="st8"
          width="281.1"
          height="130.3"
        />
      </g>
      <g id="tesla_hover" class="cursor-pointer" @mouseover="over = 'tesla'" @mouseout="over = null">
        <rect
          id="raised_hover_5_"
          x="1"
          y="263.3"
          class="st8"
          width="130.4"
          height="168.2"
        />
      </g>
      <g id="board_punto" v-bind:class="{'opac':over == 'board'}">
        <circle class="st6" cx="1569.3" cy="367" r="25" />
      </g>
      <g id="board_arrow" v-bind:class="{'opac':over != 'board'}">
        <path
          class="st7"
          d="M1568.7,402.6c-0.2,0-1,0-1,0s0.2-0.4,0.2-0.5s-0.3-0.3-0.2-0.5c0-0.2-0.2-0.6-0.2-0.6s-0.5-0.2-0.5-0.4
		s0-0.6,0-0.6l-1.1,0.1c0,0-0.4,0.2-0.5,0.5s-0.2,0.7-0.2,0.7s-0.6,1.2-0.7,1.4c0,0.2-0.4,0.9-0.4,1.2c0,0.2,0,1.5,0,1.5l-1.1,1.3
		l-1.3,1.8c0,0-0.6,1.1-0.9,1.4s-1.7,3.1-1.7,3.1l-1.4,1.6l-1.1,1.8l-0.8,1.4c0,0-1.1,1.8-1.2,1.9s-1.2,2-1.2,2l-1.4,2.5l-1.5,2.3
		c0,0-0.4,1.1-0.5,1.2s-1.5,1.9-1.5,1.9l-0.8,1.3l-1.1,1.4l-1,0.9l-0.9,0.6c0,0-0.3,0.3-0.3,0.5s-0.2,0.8,0,0.9s0.4,0.5,0.6,0.5
		s0.4-0.1,0.7,0c0.2,0.1,0.4,0.1,0.6,0s0.6-0.4,1-0.8c0.4-0.3,0.7-0.7,1.2-1.1c0.5-0.3,1.9-3,2.6-3.5s1.1-0.9,1.4-1.5
		s0.6-1.4,1.1-1.8c0.5-0.5,1.1-1.3,1.3-1.8c0.2-0.5-0.1-0.2,0.2-0.5s0.7-0.8,1-1.1c0.3-0.3,1-1.5,1.2-1.7s1.5-2.3,1.6-2.5
		s0.1-0.5,0.4-0.9c0.3-0.3,0.1-0.3,0.5-0.7c0.4-0.5,1.3-2.1,1.3-2.1s0.3-0.5,0.5-0.8s0.3-0.3,0.6-1s0.6-1.3,0.7-1.6s0.1-0.4,0.2-0.7
		s0.8-1,0.9-1.3c0-0.3,0.2-0.9,0.2-0.9l0.4-0.6c0,0,0.1-0.3,0.3-0.3c0.2,0,0.2,0.2,0.2,0.2s0,0.8-0.1,1s-0.4,0.8-0.4,1.3
		c0,0.4,0.2,0.8,0.1,1.2c-0.1,0.4-0.1,2-0.1,2.1s-0.2,0.9-0.2,1.1c0,0.1,0,1.5,0,1.5s0,1.3,0,1.4s-0.2,1.3-0.2,1.3l-0.3,1.4
		l-0.3,1.3v3.1l-0.1,2.2c0,0-0.2,1.5-0.2,1.7s-0.1,2.7-0.1,2.7l-0.5,4.7l-0.2,4.7l0.1,2.8c0,0-0.2,2.5-0.2,2.7c0,0.1,0,2.5,0,2.5
		v1.7v2.6l0.3,7l-0.3,4.2l0.3,3.8c0,0,0.2,1.8,0.1,2c0,0.2,0,1.3,0,1.3l-0.1,7c0,0,0.1,2.1,0.1,2.2s0.1,1.1,0.2,1.3
		c0,0.1,0,1.2,0,1.2l0.4,11.3c0,0-0.2,2.4-0.1,2.6c0,0.2,0.7,4.5,0.7,4.5l0.2,3.8l0.4,2.7l0.4,4.7l0.3,3.2l0.1,2.8l0.8,4.4
		c0,0-0.1,2.3,0,2.7s0.6,3.9,0.6,3.9l0.5,2.7c0,0,0.1,2.1,0.2,2.5s0.8,1.4,0.8,1.8c0,0.4,0.1,1,0.1,1.4s0,1.4,0,1.4s0,1,0.2,1.3
		c0.2,0.2,0.2,0.5,0.5,0.7s0.5,0.4,0.8,0.4s-0.1,0.3,0.3,0s0.4,0.1,0.4-0.3c0-0.5,0.1-0.2,0-0.9s0-0.7-0.2-1
		c-0.3-0.2-0.3-0.1-0.5-0.5s0.1,0.5-0.2-0.4s-0.4-0.3-0.3-0.9c0.1-0.6,0-0.7,0.1-1.3s0.1-0.8,0-1.4c-0.1-0.6-0.2-1-0.2-1.2
		s-0.7-6.6-0.7-6.6s-0.5-3.9-0.6-4.1c-0.1-0.2-0.7-1.7-0.7-2.3s0-1.3-0.1-1.8c-0.1-0.6-0.2-1.4-0.3-2c-0.1-0.6,0-1.7,0-2.1
		c0-0.3-0.1-2.2-0.1-2.2s-0.7-4.9-0.7-5.3s-0.4-7.1-0.4-7.4s-0.5-1.8-0.4-2.4c0-0.6-0.1-4.7,0-5.2s-0.2-7.1-0.2-7.1s-0.2-9-0.3-9.2
		c0-0.2-0.4-3.7-0.3-3.9s-0.1-9.1-0.1-9.4s-0.1-0.5-0.1-0.9c0-0.3,0.1-0.5,0.1-0.8s0.2-2.7,0.1-2.9s-0.3-0.6-0.3-0.8
		c0-0.2,0.4-1.4,0.4-1.4l-0.2-1.4c0,0-0.4-2.6-0.4-2.8c0-0.2,0-2.2,0.1-2.4c0.1-0.2,0.2-1.8,0.2-1.8l0.3-6.2c0,0,0.1,0.3,0-0.5
		c0-0.8,0-0.3-0.1-1.1c-0.1-0.8-0.4-0.8-0.1-1.5c0.2-0.7,0-1.4,0.3-1.8c0.3-0.5,0.3-0.4,0.4-1c0-0.6-0.1-0.7,0-1.2
		c0.1-0.4,0-5.8,0.2-6c0.1-0.2,0.5-0.4,0.6-1.4s-0.3-3-0.3-3.5s0-0.7,0.2-1.7c0.1-1.1,0-1.4,0.1-1.8s0.3-1.5,0.3-1.5
		s0.6-4.3,0.6-4.7c0-0.3,0.2-2.5,0.3-3.3c0.1-0.9-0.1-1.6,0.1-2.3c0.2-0.7,0.2-0.7,0.3-1c0.1-0.3,0.5-1.9,0.5-1.9s0.5-0.5,0.6-0.5
		s0.6,0.2,0.6,0.2s0.4,0.8,0.4,1s1.2,2.2,1.2,2.2s0.8,1.6,0.9,1.9s0.5,1.7,0.7,2.1c0.2,0.3,0.6,2,0.7,2.3s-0.2,1.4,0,1.9s1,1.8,1,2
		s0.6,3,0.7,3.2c0,0.1,0.5,2.2,0.9,2.8s0.7,1.4,0.7,1.4l0.8,1.6l0.9,1.4l0.7,0.8l0.7,0.2c0,0,0.4,0.1,0.5,0s0.1,0,0.1-0.2
		s0.6-0.2,0-0.8c-0.6-0.6-1.7-4.1-1.7-4.1s-0.2-0.7-0.5-1.2s-1.2-2.4-1.4-3.1s-0.3-3-0.4-3.5s-0.2-0.1-0.4-1.3s-0.7-3.9-0.8-4.1
		c0-0.2-0.5-1.2-0.7-1.6c-0.1-0.4-0.4-1.9-0.4-1.9s-1.3-2.6-1.3-2.7s-0.4-0.8-0.7-1.2c-0.2-0.3-0.5-0.6-0.7-1
		c-0.2-0.3-0.4-0.4-0.6-0.6s-0.2-0.4-0.5-0.4C1569.5,402.5,1568.7,402.6,1568.7,402.6z"
        />
        <g>
          <path
            class="st7"
            d="M1590.1,510.2c1.3-1.1,2.4-2.2,3.4-3.3c1-1.1,1.9-2.1,2.6-3.1s1.3-2,1.7-2.9c0.4-0.9,0.6-1.8,0.6-2.7
			c0-0.5-0.1-1-0.2-1.5s-0.3-0.9-0.6-1.3c-0.3-0.4-0.6-0.7-1-0.9s-0.9-0.3-1.5-0.3c-0.5,0-0.8,0.1-1.2,0.2c-0.3,0.1-0.7,0.3-1,0.6
			c-0.3,0.2-0.6,0.5-0.8,0.8c-0.2,0.3-0.5,0.6-0.8,0.9l-0.5-0.5c0.3-0.4,0.6-0.9,1-1.3c0.3-0.4,0.7-0.8,1.2-1.2
			c0.4-0.3,0.9-0.6,1.5-0.9c0.6-0.2,1.2-0.3,1.8-0.3c0.7,0,1.3,0.1,1.8,0.4c0.6,0.3,1,0.6,1.4,1s0.7,0.9,0.9,1.5
			c0.2,0.6,0.3,1.2,0.3,1.9c0,1.1-0.3,2.2-0.8,3.2s-1.2,2.1-2,3c-0.8,1-1.7,1.9-2.6,2.8s-1.7,1.7-2.4,2.4h8.4l0.6-1.3h0.7l-0.8,3.4
			H1590L1590.1,510.2L1590.1,510.2L1590.1,510.2z"
          />
          <path
            class="st7"
            d="M1604.5,504l8.9-11.6h1.1v10.9h2.5v1.7h-2.5v5.7h-2.2V505h-7.8V504z M1612.3,495.5L1612.3,495.5l-5.9,7.8h5.9
			V495.5z"
          />
          <path class="st7" d="M1618.1,511.1l6.1-18.9h1.7l-6.1,18.9H1618.1z" />
          <path
            class="st7"
            d="M1627.7,492.8h11.7l0.2,0.2l-9.3,18.1l-1.1-0.4l8.3-15.8h-9.1l-0.7,1.5l-0.7-0.1L1627.7,492.8z"
          />
          <path
            class="st7"
            d="M1659.3,509.7c-0.4,0.3-0.8,0.7-1.2,1s-0.9,0.4-1.4,0.4s-0.9-0.2-1.2-0.6c-0.2-0.4-0.4-0.8-0.4-1.3
			c-0.6,0.6-1.2,1-1.8,1.4s-1.3,0.5-2.2,0.5c-0.8,0-1.5-0.2-2-0.7s-0.8-1.1-0.8-1.8c0-0.5,0.1-0.9,0.3-1.3c0.2-0.3,0.5-0.7,1-1
			s1.2-0.6,2-0.9c0.9-0.3,2-0.7,3.4-1.2v-1.7c0-1.5-0.8-2.2-2.5-2.2c-0.6,0-1,0.2-1.4,0.5s-0.5,0.8-0.6,1.3c0,0.4-0.2,0.6-0.5,0.8
			c-0.3,0.2-0.6,0.3-1,0.3c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.2-0.1-0.3c0-0.3,0.1-0.6,0.2-0.9s0.3-0.5,0.6-0.7c0.3-0.2,0.6-0.4,0.9-0.6
			c0.4-0.2,0.8-0.4,1.2-0.7c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.2,0.5-0.3c0.2-0.1,0.4-0.1,0.5-0.2c0.2,0,0.4-0.1,0.6-0.1
			c0.3,0,0.6,0,1,0.1s0.7,0.2,1,0.4c0.3,0.2,0.6,0.4,0.8,0.7c0.2,0.3,0.3,0.6,0.3,0.9v7.2c0,0.5,0.1,0.9,0.2,1.1s0.4,0.3,0.7,0.3
			c0.2,0,0.3,0,0.5-0.1s0.3-0.2,0.4-0.3L1659.3,509.7z M1655.1,504.9c-0.3,0.1-0.8,0.3-1.3,0.4s-1,0.3-1.6,0.6
			c-0.5,0.2-0.9,0.5-1.3,0.8s-0.5,0.7-0.5,1.2s0.2,0.9,0.5,1.3s0.8,0.6,1.3,0.6c0.6,0,1.1-0.2,1.6-0.5c0.4-0.3,0.9-0.7,1.3-1V504.9z
			"
          />
          <path
            class="st7"
            d="M1661,510h0.5c0.4,0,0.7,0,0.9-0.1c0.2-0.1,0.4-0.3,0.4-0.8v-7c0-0.3,0-0.5-0.1-0.7s-0.1-0.3-0.2-0.5
			c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.2-0.6-0.3v-0.4c0.3-0.1,0.6-0.2,0.9-0.3s0.6-0.2,0.9-0.3c0.3-0.1,0.5-0.2,0.7-0.2
			s0.3-0.1,0.3-0.1c0.2-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0.1,0.2,0.4v10c0,0.4,0.1,0.7,0.4,0.8c0.2,0.1,0.6,0.1,0.9,0.1h0.4v0.7
			c-0.4,0-0.9,0-1.3,0c-0.5,0-0.9,0-1.4,0s-0.9,0-1.4,0s-0.9,0-1.4,0V510H1661z M1665,493.6c0,0.3-0.1,0.6-0.4,0.9s-0.5,0.4-0.9,0.4
			c-0.3,0-0.6-0.1-0.9-0.4s-0.4-0.6-0.4-0.9s0.1-0.6,0.4-0.9s0.6-0.4,0.9-0.4s0.6,0.1,0.9,0.4C1664.9,492.9,1665,493.2,1665,493.6z"
          />
          <path
            class="st7"
            d="M1668,510h0.7c0.4,0,0.7,0,0.9-0.1c0.2-0.1,0.4-0.3,0.4-0.8v-7.3c0-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3
			c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1-0.1-0.3-0.1-0.4-0.2v-0.4c1-0.4,1.7-0.7,2.2-1s0.8-0.4,1-0.4s0.2,0.1,0.2,0.3v2.3h0.1
			c0.2-0.3,0.3-0.5,0.6-0.8c0.2-0.3,0.5-0.6,0.7-0.9c0.3-0.3,0.6-0.5,0.9-0.7s0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.3
			c0.3,0.2,0.5,0.6,0.5,1c0,0.3-0.1,0.7-0.3,0.9c-0.2,0.3-0.5,0.4-0.9,0.4c-0.2,0-0.4,0-0.5-0.1s-0.2-0.1-0.3-0.2
			c-0.1-0.1-0.2-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.1,0-0.3,0-0.5,0.1s-0.4,0.2-0.6,0.4c-0.2,0.2-0.4,0.4-0.5,0.7
			c-0.2,0.3-0.2,0.6-0.2,1v6.1c0,0.4,0.1,0.7,0.4,0.8s0.5,0.1,0.9,0.1h1.5v0.7c-0.7,0-1.4,0-1.9,0c-0.6,0-1.1,0-1.7,0
			c-0.5,0-1.1,0-1.6,0s-1,0-1.5,0V510H1668z"
          />
          <path
            class="st7"
            d="M1594.1,543.6c0,0.3,0.1,0.5,0.4,0.5s0.5,0.1,0.8,0.1h1.2v0.7c-0.6,0-1.1,0-1.7,0s-1.1,0-1.7,0
			c-0.5,0-1.1,0-1.6,0s-1.1,0-1.6,0v-0.7h1c0.5,0,0.8-0.1,0.9-0.3s0.2-0.4,0.2-0.6V531c0-0.5,0-0.9-0.1-1.1
			c-0.1-0.3-0.2-0.5-0.3-0.6c-0.1-0.1-0.3-0.2-0.5-0.3s-0.4-0.1-0.6-0.2v-0.4c0.5-0.2,1-0.5,1.5-0.7s1-0.5,1.5-0.7
			c0,0,0.1,0,0.1-0.1c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0s0.1,0.1,0.1,0.2v1.8c0.3-0.2,0.5-0.5,0.8-0.7s0.6-0.4,0.9-0.6
			c0.3-0.2,0.7-0.3,1-0.4s0.7-0.2,1.1-0.2c0.7,0,1.4,0.2,2,0.5s1.1,0.7,1.5,1.3c0.4,0.5,0.7,1.1,1,1.8c0.2,0.7,0.3,1.3,0.3,2
			c0,0.9-0.2,1.7-0.5,2.5s-0.8,1.5-1.3,2.2c-0.6,0.6-1.2,1.1-2,1.5s-1.6,0.5-2.5,0.5c-0.3,0-0.7,0-1.2-0.1c-0.4-0.1-0.8-0.2-1.1-0.3
			L1594.1,543.6L1594.1,543.6z M1600.8,533.7c0-0.6-0.1-1.2-0.3-1.8c-0.2-0.6-0.5-1.1-0.8-1.6c-0.4-0.4-0.8-0.8-1.3-1.1
			s-1.2-0.4-1.8-0.4c-0.4,0-0.9,0.1-1.3,0.3s-0.8,0.4-1,0.6v7.1c0.1,0.2,0.2,0.3,0.4,0.5c0.1,0.2,0.3,0.4,0.6,0.6s0.5,0.3,0.8,0.5
			s0.7,0.2,1.1,0.2c0.6,0,1.2-0.1,1.7-0.4s0.9-0.7,1.2-1.1c0.3-0.5,0.6-1,0.7-1.6C1600.7,534.9,1600.8,534.3,1600.8,533.7z"
          />
          <path
            class="st7"
            d="M1615.9,537.6c0,0.2,0.1,0.4,0.2,0.4s0.3,0.1,0.5,0.1s0.3,0,0.5,0s0.4,0,0.5,0v0.5c-0.3,0-0.6,0.1-1,0.2
			c-0.4,0.1-0.8,0.1-1.1,0.2c-0.4,0.1-0.7,0.2-1,0.2s-0.5,0.1-0.6,0.2l-0.1-0.1v-2.1c-0.8,0.6-1.5,1.2-2.2,1.6s-1.5,0.6-2.4,0.6
			c-0.3,0-0.7-0.1-1-0.2c-0.3-0.1-0.7-0.3-0.9-0.5s-0.5-0.6-0.6-0.9c-0.2-0.4-0.2-0.9-0.2-1.4v-6.6c0-0.2,0-0.3,0-0.5
			s-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.5-0.3s-0.5-0.2-0.9-0.2v-0.5c0.2,0,0.5,0,0.9,0s0.7,0,1-0.1c0.3,0,0.6,0,0.9-0.1
			c0.3,0,0.4,0,0.5,0s0.2,0.1,0.2,0.2c0,0.1,0,0.3,0,0.6v6.7c0,0.2,0,0.5,0,0.8s0.1,0.7,0.2,1s0.4,0.6,0.7,0.9
			c0.3,0.2,0.7,0.4,1.3,0.4c0.5,0,0.9-0.2,1.5-0.5s1.1-0.7,1.6-1.1v-7c0-0.1-0.1-0.2-0.2-0.3s-0.3-0.2-0.5-0.3s-0.5-0.2-0.7-0.2
			c-0.3-0.1-0.5-0.1-0.7-0.2v-0.5c1.2-0.1,2.1-0.2,2.8-0.2c0.7-0.1,1.1-0.1,1.2-0.1s0.2,0,0.2,0.1s0,0.2,0,0.3L1615.9,537.6
			L1615.9,537.6z"
          />
          <path
            class="st7"
            d="M1619,538.3h0.7c0.4,0,0.7,0,0.9-0.1c0.2-0.1,0.4-0.3,0.4-0.8v-7.3c0-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3
			c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1-0.1-0.3-0.1-0.4-0.2v-0.4c1-0.4,1.7-0.7,2.2-1s0.8-0.4,1-0.4s0.2,0.1,0.2,0.3v2.3h0.1
			c0.2-0.3,0.3-0.5,0.6-0.8c0.2-0.3,0.5-0.6,0.7-0.9c0.3-0.3,0.6-0.5,0.9-0.7s0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.3s0.5,0.6,0.5,1
			c0,0.3-0.1,0.7-0.3,0.9c-0.2,0.3-0.5,0.4-0.9,0.4c-0.2,0-0.4,0-0.5-0.1s-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.2-0.4-0.2
			s-0.3-0.1-0.5-0.1c-0.1,0-0.3,0-0.5,0.1s-0.4,0.2-0.6,0.4s-0.4,0.4-0.5,0.7c-0.2,0.3-0.2,0.6-0.2,1v6.1c0,0.4,0.1,0.7,0.4,0.8
			s0.5,0.1,0.9,0.1h1.5v0.7c-0.7,0-1.4,0-1.9,0c-0.6,0-1.1,0-1.7,0c-0.5,0-1.1,0-1.6,0s-1,0-1.5,0v-0.6H1619z"
          />
          <path
            class="st7"
            d="M1629.3,538.3h0.5c0.4,0,0.7,0,0.9-0.1c0.2-0.1,0.4-0.3,0.4-0.8v-7c0-0.3,0-0.5-0.1-0.7s-0.1-0.3-0.2-0.5
			c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.2-0.6-0.3v-0.4c0.3-0.1,0.6-0.2,0.9-0.3s0.6-0.2,0.9-0.3s0.5-0.2,0.7-0.2
			s0.3-0.1,0.3-0.1c0.2-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0.1,0.2,0.4v10c0,0.4,0.1,0.7,0.4,0.8c0.2,0.1,0.6,0.1,0.9,0.1h0.4v0.7
			c-0.4,0-0.9,0-1.3,0c-0.5,0-0.9,0-1.4,0s-0.9,0-1.4,0s-0.9,0-1.4,0v-0.9L1629.3,538.3L1629.3,538.3z M1633.3,521.9
			c0,0.3-0.1,0.6-0.4,0.9c-0.3,0.3-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4c-0.3-0.3-0.4-0.6-0.4-0.9c0-0.3,0.1-0.6,0.4-0.9
			c0.3-0.3,0.6-0.4,0.9-0.4s0.6,0.1,0.9,0.4C1633.2,521.2,1633.3,521.5,1633.3,521.9z"
          />
          <path
            class="st7"
            d="M1643.9,527.5v1.4h-3.3v8.8c0,0.3,0.1,0.5,0.4,0.5c0.3,0.1,0.5,0.1,0.8,0.1h1.2v0.7c-0.6,0-1.1,0-1.7,0
			s-1.1,0-1.7,0c-0.5,0-1.1,0-1.6,0s-1.1,0-1.6,0v-0.7h1c0.5,0,0.8-0.1,0.9-0.3s0.2-0.4,0.2-0.6v-8.6h-1.8v-0.4
			c0.2-0.1,0.3-0.2,0.5-0.3s0.4-0.2,0.6-0.3s0.3-0.3,0.5-0.4s0.2-0.4,0.2-0.6c0-0.4,0-0.9,0.1-1.4s0.2-1,0.3-1.6s0.4-1,0.6-1.6
			c0.3-0.5,0.6-1,1-1.4c0.7-0.7,1.4-1.2,2-1.5c0.6-0.2,1-0.3,1.2-0.3c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0.1,0.4,0.1
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.6s-0.1,0.5-0.2,0.7s-0.4,0.3-0.7,0.3c-0.2,0-0.3,0-0.5-0.1
			c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.3-0.1-0.5-0.1c-0.4,0-0.7,0.1-0.9,0.3c-0.2,0.2-0.4,0.5-0.5,0.8
			s-0.2,0.6-0.2,1c0,0.3-0.1,0.6-0.1,0.9v3.7h3.2V527.5z"
          />
          <path
            class="st7"
            d="M1645.1,538.3h0.5c0.4,0,0.7,0,0.9-0.1c0.2-0.1,0.4-0.3,0.4-0.8v-7c0-0.3,0-0.5-0.1-0.7s-0.1-0.3-0.2-0.5
			c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.2-0.6-0.3v-0.4c0.3-0.1,0.6-0.2,0.9-0.3s0.6-0.2,0.9-0.3s0.5-0.2,0.7-0.2
			s0.3-0.1,0.3-0.1c0.2-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0.1,0.2,0.4v10c0,0.4,0.1,0.7,0.4,0.8c0.2,0.1,0.6,0.1,0.9,0.1h0.4v0.7
			c-0.4,0-0.9,0-1.3,0c-0.5,0-0.9,0-1.4,0s-0.9,0-1.4,0s-0.9,0-1.4,0v-0.9L1645.1,538.3L1645.1,538.3z M1649.2,521.9
			c0,0.3-0.1,0.6-0.4,0.9c-0.3,0.3-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4c-0.3-0.3-0.4-0.6-0.4-0.9c0-0.3,0.1-0.6,0.4-0.9
			c0.3-0.3,0.6-0.4,0.9-0.4s0.6,0.1,0.9,0.4C1649,521.2,1649.2,521.5,1649.2,521.9z"
          />
          <path
            class="st7"
            d="M1654.5,531.9c0,0.1-0.1,0.3-0.1,0.5s0,0.5,0.1,1c0,0.6,0.2,1.2,0.4,1.8c0.2,0.5,0.5,1,0.8,1.4s0.7,0.7,1.2,1
			c0.4,0.2,0.9,0.4,1.5,0.4c0.7,0,1.4-0.2,1.9-0.5c0.6-0.3,1.1-0.7,1.6-1.2l0.4,0.4c-0.3,0.4-0.6,0.8-0.9,1.1
			c-0.3,0.4-0.7,0.7-1.1,0.9c-0.4,0.3-0.8,0.5-1.3,0.6c-0.5,0.2-0.9,0.2-1.5,0.2c-1,0-1.9-0.2-2.5-0.6c-0.6-0.4-1.1-1-1.5-1.6
			s-0.6-1.2-0.7-1.9c-0.1-0.6-0.2-1.2-0.2-1.6c0-1.3,0.2-2.3,0.6-3.2s0.8-1.5,1.4-2c0.5-0.5,1.1-0.9,1.7-1.1
			c0.6-0.2,1.1-0.3,1.6-0.3s1,0.1,1.6,0.2c0.5,0.2,1,0.4,1.4,0.8s0.8,0.8,1,1.3c0.3,0.5,0.4,1.1,0.4,1.8c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2l-0.2,0.2L1654.5,531.9L1654.5,531.9z M1659.1,531c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.1-0.3,0.1-0.4
			c0-0.7-0.2-1.2-0.6-1.7s-0.9-0.7-1.7-0.7c-0.8,0-1.5,0.3-2,0.8s-0.9,1.3-1,2.2L1659.1,531L1659.1,531z"
          />
          <path
            class="st7"
            d="M1663.6,538.3h0.7c0.4,0,0.7,0,0.9-0.1c0.2-0.1,0.4-0.3,0.4-0.8v-7.3c0-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3
			c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1-0.1-0.3-0.1-0.4-0.2v-0.4c1-0.4,1.7-0.7,2.2-1s0.8-0.4,1-0.4s0.2,0.1,0.2,0.3v2.3h0.1
			c0.2-0.3,0.3-0.5,0.6-0.8c0.2-0.3,0.5-0.6,0.7-0.9c0.3-0.3,0.6-0.5,0.9-0.7s0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.3s0.5,0.6,0.5,1
			c0,0.3-0.1,0.7-0.3,0.9c-0.2,0.3-0.5,0.4-0.9,0.4c-0.2,0-0.4,0-0.5-0.1s-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.2-0.4-0.2
			s-0.3-0.1-0.5-0.1c-0.1,0-0.3,0-0.5,0.1s-0.4,0.2-0.6,0.4s-0.4,0.4-0.5,0.7c-0.2,0.3-0.2,0.6-0.2,1v6.1c0,0.4,0.1,0.7,0.4,0.8
			s0.5,0.1,0.9,0.1h1.5v0.7c-0.7,0-1.4,0-1.9,0c-0.6,0-1.1,0-1.7,0c-0.5,0-1.1,0-1.6,0s-1,0-1.5,0v-0.6H1663.6z"
          />
          <path
            class="st7"
            d="M1674.7,535.4c0.1,0.4,0.2,0.8,0.4,1.2c0.2,0.4,0.4,0.7,0.7,1c0.3,0.3,0.6,0.5,1,0.7s0.8,0.3,1.3,0.3
			c0.4,0,0.7-0.1,1-0.2c0.3-0.1,0.5-0.2,0.6-0.4c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.1-0.4,0.1-0.7c0-0.5-0.1-0.9-0.4-1.2
			s-0.6-0.6-1.1-0.8s-0.9-0.4-1.4-0.6c-0.5-0.2-0.9-0.4-1.4-0.7s-0.8-0.7-1.1-1.1c-0.3-0.4-0.4-1-0.4-1.7c0-0.4,0.1-0.7,0.2-1.1
			c0.1-0.4,0.4-0.7,0.7-1.1c0.3-0.3,0.7-0.6,1.3-0.8c0.5-0.2,1.1-0.3,1.8-0.3c0.4,0,0.9,0.1,1.4,0.2c0.5,0.1,1,0.3,1.5,0.6l0.2,2.6
			h-0.6c-0.1-0.8-0.4-1.5-0.9-1.9s-1.1-0.7-1.9-0.7c-0.5,0-1,0.1-1.4,0.4s-0.6,0.7-0.6,1.3c0,0.5,0.1,0.9,0.4,1.2s0.7,0.6,1.1,0.8
			s0.9,0.5,1.5,0.7c0.5,0.2,1,0.5,1.5,0.7c0.5,0.3,0.8,0.6,1.1,1.1c0.3,0.4,0.4,1,0.4,1.7c0,0.4-0.1,0.9-0.3,1.3s-0.4,0.8-0.8,1.1
			s-0.8,0.6-1.4,0.8s-1.2,0.3-2,0.3c-0.5,0-1.1-0.1-1.6-0.2c-0.6-0.2-1.1-0.4-1.5-0.6l-0.3-3L1674.7,535.4z"
          />
        </g>
      </g>
      <g id="solar_punto" v-bind:class="{'opac':over == 'solar'}">
        <circle class="st6" cx="1436.4" cy="234.2" r="25" />
      </g>
      <g id="solar_arrow" v-bind:class="{'opac':over != 'solar'}">
        <g>
          <path
            class="st7"
            d="M1432,15.5c-0.3,0-0.7,0-1,0.1c-0.2,0.8-0.6,1.6-0.7,2.4c-0.3,5.7-0.7,11.3-0.9,17c-0.1,2.8,0.2,5.7,0.2,8.6
			c-0.1,5.6-0.5,11.2-0.6,16.8c-0.1,5.3,0,10.6,0,15.9c0,7.4,0,14.8,0,22.2c0,5-0.1,10-0.1,14.9c0,7.8-0.1,15.5,0.1,23.3
			c0.2,5.6,0.8,11.1,1.2,16.7c0.3,5.2,0.3,10.4,0.7,15.5c0.6,8.6,1.5,17.2,2.1,25.8c0.1,1.5-0.4,3.1-0.9,4.5
			c-0.4,1.2-1.5,1.4-2.4,0.3c-0.8-1-1.5-2.1-1.9-3.2c-1.5-5.1-2.8-10.3-4.3-15.4c-0.6-2.2-1.5-4.4-2.3-6.6c-0.2,0.1-0.5,0.2-0.7,0.2
			c0.1,0.6,0.2,1.2,0.3,1.8c1.4,5.4,2.6,10.8,4.1,16.1c0.8,2.8,2,5.7,3.7,8c2.2,3,4.6,2.2,5.9-1.3c3.5-9.9,7.1-19.7,10.5-29.6
			c0.2-0.7,0.1-1.5,0.2-2.2c-0.3-0.1-0.6-0.1-0.8-0.2c-0.7,1.2-1.5,2.3-1.9,3.5c-1.9,5.9-3.7,11.9-5.6,17.8
			c-0.5,1.4-1.2,2.8-1.9,4.2c-0.2-0.1-0.4-0.1-0.6-0.2c-0.2-1.9-0.3-3.7-0.5-5.6c-0.7-6.4-1.5-12.8-2-19.2c-0.5-6-0.6-12.1-0.9-18.2
			c-0.3-8.7-0.8-17.4-0.9-26.1c-0.1-12.2,0-24.3,0-36.5c0-6.7,0.1-13.5,0.1-20.2c0.1-6.4,0.1-12.8,0.2-19.3c0-4.8,0-9.7,0.1-14.5
			c0.1-5.2,0.3-10.3,1.6-15.4C1432.6,17.2,1432.1,16.3,1432,15.5z"
          />
          <path
            class="st7"
            d="M1432,15.5c0.7,0,1.5,1.7,1.4,2.4c-1.3,5.1-1.5,10.2-1.6,15.4c-0.1,4.8-0.1,9.7-0.1,14.5
			c-0.1,6.4-0.1,12.8-0.2,19.3c-0.1,6.7-0.1,13.5-0.1,20.2c0,12.2-0.1,24.3,0,36.5c0.1,8.7,0.6,17.4,0.9,26.1
			c0.2,6.1,0.4,12.1,0.9,18.2c0.5,6.4,1.3,12.8,2,19.2c0.2,1.9,0.2,2.4,0.4,4.3v0.4c0.6-1.4,0.4-1.7,0.8-3.1
			c1.9-5.9,4.5-11.9,6.4-17.8c0.4-1.3,1.4-3.8,1.9-3.5c0.3,0.1,0.6-0.1,0.8,0.2c0.3,0.4,0,1.5-0.2,2.2c-3.5,9.9-7,19.7-10.5,29.6
			c-1.3,3.5-4.2,4.8-6.4,1.8c-1.7-2.3-3.1-5.7-3.9-8.5c-1.6-5.3-2.1-10.7-3.5-16.1c-0.1-0.6-0.2-1.2-0.3-1.8c0-0.8,0.8-1.3,1.3-0.9
			c0.8,2.2,1.2,5,1.8,7.2c1.5,5.1,2.8,10.3,4.3,15.4c0.3,1.2,1.1,2.2,1.9,3.2c0.9,1.1,2,0.9,2.4-0.3c0.5-1.4,1-3,0.9-4.5
			c-0.6-8.6-1.5-17.2-2.1-25.8c-0.4-5.2-0.4-10.4-0.7-15.5c-0.3-5.6-1-11.1-1.2-16.7c-0.2-7.8-0.1-15.5-0.1-23.3
			c0-5,0.1-10,0.1-14.9c0-7.4,0-14.8,0-22.2c0-5.3-0.1-10.6,0-15.9c0.1-5.6,0.5-11.2,0.6-16.8c0.1-2.9-0.3-5.7-0.2-8.6
			c0.2-5.7,0.5-11.4,0.9-17c0-0.8,0.2-2.2,0.7-2.4C1431.3,15.5,1431.6,15.5,1432,15.5z"
          />
        </g>
        <g>
          <path
            class="st7"
            d="M1454.2,33.5h0.6c0.1,0.5,0.3,0.9,0.5,1.3c0.2,0.4,0.5,0.8,0.8,1.2s0.7,0.6,1.1,0.8s0.9,0.3,1.4,0.3
			s0.9-0.1,1.3-0.2c0.4-0.2,0.8-0.4,1.1-0.7s0.5-0.6,0.7-1s0.3-0.8,0.3-1.3c0-0.7-0.2-1.4-0.5-1.8c-0.4-0.5-0.8-0.9-1.3-1.2
			s-1.1-0.7-1.7-1s-1.2-0.7-1.7-1.1s-1-0.9-1.3-1.5c-0.4-0.6-0.5-1.4-0.5-2.3c0-0.8,0.1-1.5,0.4-2.1s0.6-1.1,1.1-1.5s1-0.7,1.7-0.9
			c0.6-0.2,1.3-0.3,2.1-0.3c0.5,0,1,0,1.5,0.1s1,0.2,1.5,0.2l0.4,3.1h-0.6c-0.1-0.9-0.5-1.5-1-2s-1.3-0.7-2.1-0.7
			c-0.9,0-1.7,0.3-2.2,0.9c-0.5,0.6-0.8,1.3-0.8,2.2c0,0.6,0.1,1.1,0.3,1.5c0.2,0.4,0.5,0.8,0.9,1.1s0.8,0.6,1.2,0.8
			c0.4,0.2,0.9,0.5,1.4,0.7s0.9,0.5,1.4,0.8c0.4,0.3,0.9,0.6,1.2,1s0.6,0.8,0.9,1.3c0.2,0.5,0.3,1.1,0.3,1.8c0,0.8-0.2,1.5-0.5,2.1
			s-0.8,1.1-1.3,1.6c-0.5,0.4-1.2,0.7-1.8,1c-0.7,0.2-1.4,0.3-2.1,0.3c-1.3,0-2.5-0.3-3.7-0.8L1454.2,33.5z"
          />
          <path
            class="st7"
            d="M1466.4,32.1c0-0.8,0.1-1.5,0.4-2.2s0.7-1.3,1.2-1.9c0.5-0.5,1.1-0.9,1.8-1.3c0.7-0.3,1.4-0.5,2.2-0.5
			c0.8,0,1.6,0.1,2.4,0.4c0.7,0.3,1.4,0.7,1.9,1.1c0.5,0.5,0.9,1.1,1.2,1.8c0.3,0.7,0.5,1.5,0.5,2.4c0,0.8-0.1,1.5-0.4,2.2
			s-0.7,1.3-1.2,1.9s-1.1,0.9-1.8,1.3c-0.7,0.3-1.4,0.5-2.2,0.5c-0.8,0-1.6-0.1-2.3-0.4c-0.7-0.3-1.3-0.7-1.9-1.1
			c-0.5-0.5-1-1.1-1.3-1.8C1466.5,33.8,1466.4,33,1466.4,32.1z M1468.6,31.9c0,0.5,0.1,1.1,0.2,1.7c0.1,0.6,0.4,1.2,0.6,1.7
			c0.3,0.5,0.7,1,1.1,1.4c0.5,0.4,1,0.5,1.6,0.5s1.1-0.2,1.6-0.5c0.4-0.3,0.8-0.7,1.1-1.2c0.3-0.5,0.5-1,0.6-1.6s0.2-1.1,0.2-1.6
			s-0.1-1.1-0.2-1.7c-0.1-0.6-0.4-1.2-0.6-1.7c-0.3-0.5-0.7-1-1.1-1.4c-0.5-0.4-1-0.5-1.7-0.5c-0.6,0-1.1,0.2-1.6,0.5
			c-0.4,0.3-0.8,0.7-1.1,1.2c-0.3,0.5-0.5,1-0.6,1.6C1468.7,30.8,1468.6,31.4,1468.6,31.9z"
          />
          <path
            class="st7"
            d="M1479.7,36.9h0.5c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7V22.4c0-0.4,0-0.7-0.1-0.9
			c0-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.2v-0.4c0.5-0.2,1-0.3,1.4-0.5c0.5-0.2,0.8-0.4,1.1-0.5
			c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.3V36c0,0.4,0.1,0.6,0.4,0.7c0.2,0.1,0.5,0.1,0.9,0.1h0.4v0.6
			c-0.4,0-0.8,0-1.3,0c-0.4,0-0.9,0-1.3,0s-0.9,0-1.3,0s-0.9,0-1.3,0V36.9z"
          />
          <path
            class="st7"
            d="M1497.1,36.5c-0.3,0.3-0.7,0.6-1.1,0.9c-0.4,0.3-0.8,0.4-1.3,0.4s-0.9-0.2-1.1-0.6c-0.2-0.4-0.4-0.8-0.4-1.2
			c-0.6,0.5-1.1,1-1.7,1.3s-1.3,0.5-2,0.5c-0.8,0-1.4-0.2-1.9-0.7s-0.7-1-0.7-1.7c0-0.5,0.1-0.9,0.3-1.2c0.2-0.3,0.5-0.6,1-0.9
			s1.1-0.6,1.9-0.9c0.8-0.3,1.9-0.7,3.2-1.1v-1.6c0-1.4-0.8-2.1-2.4-2.1c-0.5,0-1,0.1-1.3,0.4c-0.3,0.3-0.5,0.7-0.5,1.3
			c0,0.3-0.2,0.6-0.5,0.8s-0.6,0.3-0.9,0.3c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.3,0.1-0.6,0.2-0.9s0.3-0.5,0.6-0.7
			c0.2-0.2,0.5-0.4,0.9-0.6c0.3-0.2,0.7-0.4,1.2-0.6c0.2-0.1,0.4-0.2,0.6-0.3s0.3-0.2,0.5-0.2c0.2-0.1,0.3-0.1,0.5-0.2
			c0.2,0,0.4-0.1,0.6-0.1c0.3,0,0.6,0,0.9,0.1s0.7,0.2,1,0.4s0.6,0.4,0.8,0.6c0.2,0.3,0.3,0.5,0.3,0.9v6.8c0,0.5,0.1,0.9,0.2,1.1
			c0.2,0.2,0.4,0.3,0.6,0.3s0.3,0,0.5-0.1s0.3-0.2,0.4-0.3L1497.1,36.5z M1493.1,32.1c-0.3,0.1-0.7,0.3-1.2,0.4
			c-0.5,0.1-1,0.3-1.5,0.5s-0.9,0.5-1.2,0.8c-0.3,0.3-0.5,0.7-0.5,1.1c0,0.5,0.2,0.9,0.5,1.2c0.3,0.4,0.7,0.5,1.2,0.5
			c0.6,0,1.1-0.1,1.5-0.4s0.8-0.6,1.2-1V32.1z"
          />
          <path
            class="st7"
            d="M1498.5,36.9h0.7c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-6.9c0-0.2,0-0.4-0.1-0.5s-0.2-0.2-0.4-0.3
			c-0.1-0.1-0.3-0.1-0.4-0.2s-0.3-0.1-0.4-0.1v-0.4c0.9-0.4,1.6-0.7,2.1-1s0.8-0.4,0.9-0.4s0.2,0.1,0.2,0.3v2.2l0,0
			c0.1-0.2,0.3-0.5,0.5-0.8s0.4-0.6,0.7-0.8c0.3-0.3,0.5-0.5,0.9-0.6c0.3-0.2,0.7-0.3,1-0.3s0.6,0.1,0.9,0.3s0.5,0.5,0.5,1
			c0,0.3-0.1,0.6-0.3,0.9s-0.5,0.4-0.8,0.4c-0.2,0-0.3,0-0.5-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.3-0.2
			c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.5,0.1c-0.2,0.1-0.4,0.2-0.6,0.4s-0.4,0.4-0.5,0.6c-0.1,0.3-0.2,0.6-0.2,0.9V36
			c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h1.4v0.6c-0.7,0-1.3,0-1.8,0s-1.1,0-1.6,0s-1,0-1.5,0s-1,0-1.4,0v-0.5
			C1498.4,36.9,1498.5,36.9,1498.5,36.9z"
          />
          <path
            class="st7"
            d="M1518.6,41.8c0,0.3,0.1,0.5,0.4,0.5c0.2,0,0.5,0.1,0.8,0.1h1.1V43c-0.5,0-1,0-1.6,0c-0.5,0-1,0-1.6,0
			c-0.5,0-1,0-1.5,0s-1,0-1.5,0v-0.6h0.9c0.5,0,0.7-0.1,0.9-0.3c0.1-0.2,0.2-0.3,0.2-0.5V30.1c0-0.5,0-0.8-0.1-1.1s-0.2-0.4-0.3-0.6
			c-0.1-0.1-0.3-0.2-0.5-0.3s-0.3-0.1-0.5-0.2v-0.4c0.5-0.2,0.9-0.5,1.4-0.7s1-0.4,1.5-0.6c0,0,0.1,0,0.1-0.1s0.1,0,0.1,0
			s0.1,0,0.2,0s0.1,0.1,0.1,0.2V28c0.2-0.2,0.5-0.4,0.8-0.6s0.6-0.4,0.9-0.6c0.3-0.2,0.6-0.3,1-0.4s0.7-0.2,1.1-0.2
			c0.7,0,1.3,0.2,1.9,0.5s1,0.7,1.4,1.2s0.7,1,0.9,1.7c0.2,0.6,0.3,1.3,0.3,1.9c0,0.8-0.2,1.6-0.5,2.4c-0.3,0.8-0.7,1.4-1.3,2
			c-0.5,0.6-1.2,1.1-1.9,1.4c-0.7,0.3-1.5,0.5-2.4,0.5c-0.3,0-0.7,0-1.1-0.1s-0.8-0.2-1.1-0.3v4.4L1518.6,41.8L1518.6,41.8z
			M1524.8,32.5c0-0.6-0.1-1.2-0.3-1.7c-0.2-0.6-0.4-1-0.8-1.5c-0.3-0.4-0.8-0.8-1.3-1c-0.5-0.3-1.1-0.4-1.7-0.4
			c-0.4,0-0.8,0.1-1.2,0.3s-0.7,0.4-1,0.5v6.6c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.2,0.5,0.3,0.8,0.4
			c0.3,0.1,0.6,0.2,1,0.2c0.6,0,1.1-0.1,1.6-0.4c0.5-0.3,0.8-0.6,1.1-1.1s0.5-0.9,0.7-1.5C1524.8,33.6,1524.8,33,1524.8,32.5z"
          />
          <path
            class="st7"
            d="M1539.2,36.5c-0.3,0.3-0.7,0.6-1.1,0.9c-0.4,0.3-0.8,0.4-1.3,0.4s-0.9-0.2-1.1-0.6c-0.2-0.4-0.4-0.8-0.4-1.2
			c-0.6,0.5-1.1,1-1.7,1.3s-1.3,0.5-2,0.5c-0.8,0-1.4-0.2-1.9-0.7s-0.7-1-0.7-1.7c0-0.5,0.1-0.9,0.3-1.2c0.2-0.3,0.5-0.6,1-0.9
			c0.5-0.3,1.1-0.6,1.9-0.9c0.8-0.3,1.9-0.7,3.2-1.1v-1.6c0-1.4-0.8-2.1-2.4-2.1c-0.5,0-1,0.1-1.3,0.4c-0.3,0.3-0.5,0.7-0.5,1.3
			c0,0.3-0.2,0.6-0.5,0.8s-0.6,0.3-0.9,0.3c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.3,0.1-0.6,0.2-0.9
			c0.1-0.2,0.3-0.5,0.6-0.7c0.2-0.2,0.5-0.4,0.9-0.6c0.4-0.2,0.7-0.4,1.2-0.6c0.2-0.1,0.4-0.2,0.6-0.3s0.3-0.2,0.5-0.2
			s0.3-0.1,0.5-0.2c0.2,0,0.4-0.1,0.6-0.1c0.3,0,0.6,0,0.9,0.1s0.7,0.2,1,0.4s0.6,0.4,0.8,0.6c0.2,0.3,0.3,0.5,0.3,0.9v6.8
			c0,0.5,0.1,0.9,0.2,1.1s0.4,0.3,0.6,0.3s0.3,0,0.5-0.1s0.3-0.2,0.4-0.3L1539.2,36.5z M1535.2,32.1c-0.3,0.1-0.7,0.3-1.2,0.4
			c-0.5,0.1-1,0.3-1.5,0.5s-0.9,0.5-1.2,0.8c-0.3,0.3-0.5,0.7-0.5,1.1c0,0.5,0.2,0.9,0.5,1.2c0.3,0.4,0.7,0.5,1.2,0.5
			c0.6,0,1.1-0.1,1.5-0.4s0.8-0.6,1.2-1V32.1z"
          />
          <path
            class="st7"
            d="M1542.3,29.6c0-0.2,0-0.4-0.1-0.6c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.2-0.7-0.3
			v-0.5c0.5-0.2,1-0.4,1.6-0.5c0.5-0.2,1.1-0.4,1.7-0.6c0.1,0,0.1,0.1,0.1,0.2v1.6c0.7-0.5,1.4-0.9,2-1.3c0.7-0.4,1.4-0.5,2.2-0.5
			c0.5,0,1,0.1,1.3,0.3s0.6,0.4,0.9,0.7c0.2,0.3,0.4,0.7,0.5,1.1c0.1,0.4,0.1,0.9,0.1,1.3v6.3c0,0.4,0.1,0.6,0.4,0.7
			c0.2,0.1,0.5,0.1,0.9,0.1h0.4v0.6c-0.4,0-0.9,0-1.3,0s-0.9,0-1.3,0s-0.9,0-1.3,0s-0.9,0-1.3,0v-0.6h0.4c0.3,0,0.6,0,0.8-0.1
			c0.2-0.1,0.3-0.3,0.3-0.7v-6.4c0-0.7-0.2-1.2-0.6-1.6s-0.9-0.5-1.6-0.5c-0.2,0-0.4,0-0.7,0.1c-0.2,0.1-0.5,0.2-0.7,0.3
			c-0.3,0.1-0.5,0.2-0.8,0.4c-0.3,0.1-0.5,0.3-0.7,0.4V36c0,0.4,0.1,0.6,0.4,0.7c0.2,0.1,0.5,0.1,0.9,0.1h0.4v0.6
			c-0.4,0-0.9,0-1.3,0s-0.9,0-1.3,0s-0.9,0-1.3,0s-0.9,0-1.3,0v-0.6h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7L1542.3,29.6
			L1542.3,29.6z"
          />
          <path
            class="st7"
            d="M1556.4,30.7c0,0.1,0,0.3,0,0.5s0,0.5,0,0.9c0,0.6,0.1,1.1,0.3,1.6c0.2,0.5,0.5,1,0.8,1.3
			c0.3,0.4,0.7,0.7,1.1,0.9s0.9,0.3,1.4,0.3c0.7,0,1.3-0.2,1.8-0.5s1-0.7,1.5-1.2l0.3,0.3c-0.3,0.4-0.6,0.7-0.9,1.1
			c-0.3,0.3-0.7,0.6-1,0.9c-0.4,0.3-0.8,0.5-1.2,0.6c-0.4,0.1-0.9,0.2-1.4,0.2c-1,0-1.8-0.2-2.4-0.6c-0.6-0.4-1.1-0.9-1.4-1.5
			s-0.6-1.2-0.7-1.8s-0.2-1.1-0.2-1.5c0-1.2,0.2-2.2,0.5-3c0.4-0.8,0.8-1.4,1.3-1.9s1.1-0.8,1.6-1c0.6-0.2,1.1-0.3,1.5-0.3
			c0.5,0,1,0.1,1.5,0.2c0.5,0.2,0.9,0.4,1.3,0.7s0.7,0.7,1,1.2s0.4,1,0.4,1.7c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2l-0.2,0.1h-6.9
			L1556.4,30.7L1556.4,30.7z M1560.8,30c0.3,0,0.5-0.1,0.6-0.2s0.1-0.3,0.1-0.4c0-0.6-0.2-1.1-0.5-1.6c-0.4-0.4-0.9-0.7-1.6-0.7
			c-0.8,0-1.4,0.3-1.9,0.8s-0.8,1.2-1,2.1C1556.5,30,1560.8,30,1560.8,30z"
          />
          <path
            class="st7"
            d="M1565.3,36.9h0.5c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7V22.4c0-0.4,0-0.7-0.1-0.9
			c0-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.2v-0.4c0.5-0.2,1-0.3,1.4-0.5c0.5-0.2,0.8-0.4,1.1-0.5
			c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.3V36c0,0.4,0.1,0.6,0.4,0.7c0.2,0.1,0.5,0.1,0.9,0.1h0.4v0.6
			c-0.4,0-0.8,0-1.3,0c-0.4,0-0.9,0-1.3,0s-0.9,0-1.3,0s-0.9,0-1.3,0V36.9z"
          />
          <path
            class="st7"
            d="M1572.8,34.1c0.1,0.4,0.2,0.8,0.4,1.1c0.2,0.4,0.4,0.7,0.6,0.9c0.3,0.3,0.6,0.5,0.9,0.6
			c0.4,0.2,0.7,0.2,1.2,0.2c0.4,0,0.7,0,0.9-0.1c0.3-0.1,0.5-0.2,0.6-0.4c0.2-0.2,0.3-0.4,0.3-0.6c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.5-0.1-0.9-0.4-1.2s-0.6-0.6-1-0.8s-0.8-0.4-1.3-0.6c-0.5-0.2-0.9-0.4-1.3-0.7s-0.7-0.6-1-1s-0.4-0.9-0.4-1.6
			c0-0.3,0.1-0.7,0.2-1.1c0.1-0.4,0.4-0.7,0.7-1s0.7-0.6,1.2-0.8s1.1-0.3,1.7-0.3c0.4,0,0.8,0.1,1.3,0.2c0.5,0.1,1,0.3,1.5,0.5
			l0.2,2.5h-0.6c-0.1-0.8-0.4-1.4-0.8-1.8s-1-0.6-1.8-0.6c-0.5,0-0.9,0.1-1.3,0.4c-0.4,0.3-0.6,0.7-0.6,1.2s0.1,0.9,0.4,1.1
			s0.6,0.5,1.1,0.8c0.4,0.2,0.9,0.4,1.4,0.6c0.5,0.2,0.9,0.4,1.4,0.7c0.4,0.3,0.8,0.6,1.1,1s0.4,0.9,0.4,1.6c0,0.4-0.1,0.8-0.2,1.2
			c-0.2,0.4-0.4,0.7-0.8,1.1c-0.3,0.3-0.8,0.6-1.3,0.8s-1.2,0.3-1.9,0.3c-0.5,0-1-0.1-1.5-0.2c-0.5-0.2-1-0.4-1.4-0.6l-0.3-2.8
			L1572.8,34.1z"
          />
        </g>
      </g>
      <g id="fsc_punto" v-bind:class="{'opac':over == 'fsc'}">
        <circle class="st6" cx="1250.6" cy="350.5" r="25" />
      </g>
      <g id="fsc_arrow" v-bind:class="{'opac':over != 'fsc'}">
        <path
          class="st7"
          d="M1250.9,390.4c0,0-1.8,1.2-2.2,1.8s-3.1,2.6-3.1,2.6s-0.8,1.3-1.1,1.9c-0.2,0.6-2.1,6.1-2.7,7.3
		c-0.6,1.2-3.2,7.6-3.2,7.6l-1.9,4.5l-2.1,4l-1.1,3.8l-1.1,2.6l-0.8,3.6c0,0-0.2,2.7,0.5,3.1s1.3,1.2,2,0s2.5-5.3,2.5-5.3
		s1.1-3.1,1.4-4.1c0.4-1.1,0.4-2.8,0.9-3.4c0.6-0.6,2.3-5.6,2.3-5.6l1.4-3.6c0,0,0.1-1.9,0.6-2.6s0.7-2.2,1.1-2.7s0.7-1.3,0.9-2.2
		c0.2-0.9,0.8-1.9,0.8-1.9l-0.5,3.1l-0.4,5.5l-1.4,11.9L1243,434v10.7v8.1l-0.5,16.6l0.7,1.8l0.4,16.6l0.4,4c0,0,1.2,11.3,1.2,11.6
		c0,0.4,0.7,7.4,0.7,7.4l0.7,7.9l1.2,5.6l0.6,6c0,0,0.8,3.2,2,2.7s1.5-0.6,1.3-2.9c-0.2-2.3-0.7-2.9-0.8-4.1
		c-0.1-1.2-1.8-8.1-1.8-8.7s-1.3-12.7-1.3-12.7l-0.9-12.9c0,0-0.8-8.7-0.7-10.1c0.1-1.4,0-10.1,0-10.1l-0.6-16.7l0.2-5.6
		c0,0,0.1-16.1,0.1-16.7s1.4-20.2,2-21.4c0.6-1.2,0.6-12.9,0.6-12.9l0.1-1.9l1.5-1.2c0,0,1.4-1.3,1.6-0.6c0.2,0.7,1.3,2.3,1.3,2.8
		s1.4,4.5,1.5,4.8c0.1,0.4,1.5,3.2,1.5,3.5c0,0.4,0.5,3.4,0.7,3.8s1.6,5.2,1.6,5.2l1.5,5.6l0.9,3.4l1.5,2.8c0,0,0,1.3,0.6,1.4
		s0.7,0.6,1.5,0.5s1.5-2,1.5-2l-1.1-1.2c0,0-3.9-8.9-3.8-9.4s-0.4-2.6-0.6-3.8c-0.2-1.2-0.6-2.3-1.2-4.2c-0.6-1.9-1.6-3.6-1.6-4.2
		s-1.3-4.6-1.6-5.3s-1.5-3.1-1.8-3.9c-0.2-0.8-1.6-3.1-1.6-3.1L1250.9,390.4z"
        />
        <g>
          <path
            class="st7"
            d="M1293.5,495c0,0.4,0.1,0.8,0.2,1s0.3,0.4,0.5,0.5s0.4,0.2,0.7,0.2c0.3,0,0.6,0,0.9,0v0.6c-0.6,0-1.2,0-1.8,0
			c-0.6,0-1.1,0-1.7,0s-1.1,0-1.7,0s-1.2,0-1.8,0v-0.6c0.5,0,1,0,1.3-0.1s0.5-0.2,0.7-0.4c0.2-0.2,0.3-0.5,0.3-0.8
			c0-0.4,0.1-0.8,0.1-1.4v-10.9c0-0.5,0-0.9-0.1-1.2c-0.1-0.3-0.2-0.5-0.3-0.6s-0.4-0.2-0.7-0.3s-0.7-0.1-1.2-0.1v-0.6
			c0.9,0,1.8,0,2.6,0c0.9,0,1.8,0,2.7,0c1.1,0,2.2,0,3.3,0s2.2,0,3.3,0l0.1,3.4l-0.6,0.1c-0.1-0.5-0.2-1-0.3-1.3s-0.4-0.6-0.7-0.9
			c-0.3-0.2-0.7-0.4-1.2-0.5s-1-0.1-1.7-0.1h-2.9v6.9h2.9c0.5,0,0.9,0,1.2-0.1c0.3,0,0.5-0.1,0.7-0.3c0.2-0.1,0.3-0.4,0.4-0.6
			c0.1-0.3,0.2-0.6,0.2-1.1h0.6c0,0.1,0,0.3,0,0.5s0,0.4,0,0.7c0,0.2,0,0.5,0,0.8s0,0.5,0,0.7c0,0.4,0,0.8,0,1.3s0,0.9,0.1,1.2h-0.5
			c-0.1-0.4-0.2-0.8-0.2-1.1c-0.1-0.3-0.2-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.4c-0.3-0.1-0.7-0.1-1.2-0.1h-3v6.3H1293.5z"
          />
          <path
            class="st7"
            d="M1302.8,493.4h0.6c0.1,0.5,0.3,0.9,0.5,1.3s0.5,0.8,0.8,1.2c0.3,0.3,0.7,0.6,1.1,0.8c0.4,0.2,0.9,0.3,1.4,0.3
			s0.9-0.1,1.3-0.2c0.4-0.2,0.8-0.4,1.1-0.7c0.3-0.3,0.5-0.6,0.7-1s0.3-0.8,0.3-1.3c0-0.7-0.2-1.4-0.5-1.8c-0.4-0.5-0.8-0.9-1.3-1.2
			c-0.5-0.3-1.1-0.7-1.7-1s-1.2-0.7-1.7-1.1s-1-0.9-1.3-1.5s-0.5-1.4-0.5-2.3c0-0.8,0.1-1.5,0.4-2.1s0.6-1.1,1.1-1.5s1-0.7,1.6-0.9
			s1.3-0.3,2.1-0.3c0.5,0,1,0,1.5,0.1s1,0.2,1.5,0.2l0.4,3.1h-0.6c-0.1-0.9-0.5-1.5-1-2s-1.3-0.7-2.1-0.7c-0.9,0-1.6,0.3-2.2,0.9
			c-0.5,0.6-0.8,1.3-0.8,2.2c0,0.6,0.1,1.1,0.3,1.5c0.2,0.4,0.5,0.8,0.9,1.1s0.8,0.6,1.2,0.8s0.9,0.5,1.4,0.7s0.9,0.5,1.4,0.8
			c0.4,0.3,0.9,0.6,1.2,1c0.4,0.4,0.6,0.8,0.9,1.3c0.2,0.5,0.3,1.1,0.3,1.8c0,0.8-0.2,1.5-0.5,2.1s-0.8,1.1-1.3,1.6s-1.2,0.7-1.8,1
			c-0.7,0.2-1.4,0.3-2.1,0.3c-1.3,0-2.6-0.3-3.7-0.8L1302.8,493.4z"
          />
          <path
            class="st7"
            d="M1330.6,484.8h-0.6c-0.1-0.7-0.3-1.3-0.6-1.8s-0.7-0.9-1.1-1.2c-0.5-0.3-1-0.6-1.6-0.7s-1.2-0.2-1.9-0.2
			c-0.7,0-1.5,0.1-2.3,0.4s-1.6,0.7-2.2,1.3s-1.3,1.4-1.7,2.4s-0.7,2.2-0.7,3.7c0,1.2,0.2,2.4,0.6,3.4s0.9,1.9,1.6,2.7
			c0.7,0.8,1.5,1.3,2.3,1.8c0.9,0.4,1.8,0.6,2.7,0.6c0.6,0,1.2-0.1,1.7-0.3c0.5-0.2,1-0.5,1.5-0.9c0.4-0.4,0.8-0.8,1.1-1.3
			c0.3-0.5,0.5-1.1,0.6-1.7l0.6,0.1l-0.6,3.6c-0.3,0.2-0.6,0.3-1.1,0.5c-0.4,0.2-0.9,0.3-1.4,0.4s-1,0.2-1.5,0.3
			c-0.5,0.1-1,0.1-1.4,0.1c-1.4,0-2.7-0.2-3.9-0.7c-1.1-0.4-2.1-1.1-2.9-1.9s-1.4-1.8-1.9-2.9c-0.4-1.1-0.7-2.4-0.7-3.8
			c0-1.3,0.3-2.4,0.8-3.5s1.2-2,2.1-2.7c0.9-0.8,1.8-1.4,3-1.8s2.3-0.6,3.5-0.6c1,0,2,0.1,2.9,0.4s1.9,0.6,2.8,0.9L1330.6,484.8z"
          />
          <path
            class="st7"
            d="M1352.2,492.5c0.1,0.2,0.2,0.5,0.3,0.9c0.1,0.4,0.2,0.7,0.4,1.1c0.1,0.4,0.3,0.7,0.4,1s0.2,0.5,0.3,0.6
			c0.2,0.2,0.4,0.4,0.8,0.5c0.3,0.1,0.7,0.1,1.1,0.1v0.6c-0.5,0-0.9,0-1.4,0s-0.9,0-1.4,0c-0.6,0-1.2,0-1.8,0c-0.6,0-1.2,0-1.8,0
			v-0.6h0.4c0.3,0,0.6,0,0.9-0.1s0.4-0.3,0.4-0.7c0-0.1,0-0.3-0.1-0.6c-0.1-0.3-0.2-0.6-0.2-0.8l-1.2-3.5h-5.7l-0.5,1.7
			c-0.2,0.5-0.3,1-0.5,1.6s-0.2,1-0.2,1.3s0,0.5,0.1,0.7c0.1,0.1,0.2,0.3,0.4,0.3c0.2,0.1,0.3,0.1,0.5,0.1s0.4,0,0.6,0h0.6v0.6
			c-0.5,0-1,0-1.4,0c-0.5,0-1,0-1.4,0c-0.5,0-1,0-1.5,0s-1,0-1.5,0v-0.6c0.4,0,0.7-0.1,0.9-0.2c0.2-0.1,0.4-0.1,0.6-0.3
			c0.2-0.1,0.3-0.3,0.4-0.5s0.3-0.5,0.4-0.8c0.2-0.4,0.4-0.9,0.5-1.4c0.2-0.5,0.3-0.9,0.5-1.4l3.1-8.7c0.1-0.3,0.2-0.6,0.3-1
			c0.1-0.4,0.2-0.8,0.2-1.2c0-0.1,0-0.2,0-0.3s0-0.2,0-0.3c0.3-0.1,0.5-0.3,0.8-0.4c0.3-0.2,0.5-0.4,0.6-0.6h0.4L1352.2,492.5z
			M1343.9,490h5l-2.6-7.2l0,0L1343.9,490z"
          />
          <path
            class="st7"
            d="M1365.9,487.9c0,0.3-0.1,0.5-0.2,0.7c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.5-0.7c-0.2-0.3-0.5-0.5-0.8-0.7s-0.8-0.3-1.3-0.3s-1,0.1-1.4,0.4s-0.7,0.6-1,1s-0.5,0.9-0.6,1.4
			s-0.2,1-0.2,1.4c0,0.6,0.1,1.2,0.3,1.8c0.2,0.6,0.5,1.1,0.8,1.5c0.4,0.4,0.8,0.8,1.3,1c0.5,0.3,1.1,0.4,1.8,0.4
			c0.6,0,1.2-0.1,1.6-0.3c0.4-0.2,0.9-0.5,1.4-0.8l0.4,0.4c-0.6,0.7-1.3,1.3-2.1,1.8c-0.8,0.5-1.7,0.7-2.7,0.7
			c-0.6,0-1.2-0.1-1.8-0.4s-1.1-0.7-1.6-1.1s-0.8-1-1.1-1.6c-0.3-0.6-0.4-1.2-0.4-1.9c0-0.9,0.2-1.7,0.5-2.5s0.7-1.5,1.3-2.1
			s1.2-1.1,1.9-1.4c0.7-0.3,1.6-0.5,2.4-0.5c0.3,0,0.7,0,1,0.1c0.4,0.1,0.7,0.2,1.1,0.3c0.3,0.1,0.6,0.3,0.8,0.5
			S1365.9,487.6,1365.9,487.9z"
          />
          <path
            class="st7"
            d="M1376.8,487.9c0,0.3-0.1,0.5-0.2,0.7c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.5-0.7c-0.2-0.3-0.5-0.5-0.8-0.7s-0.8-0.3-1.3-0.3s-1,0.1-1.4,0.4s-0.7,0.6-1,1s-0.5,0.9-0.6,1.4
			s-0.2,1-0.2,1.4c0,0.6,0.1,1.2,0.3,1.8c0.2,0.6,0.5,1.1,0.8,1.5c0.4,0.4,0.8,0.8,1.3,1c0.5,0.3,1.1,0.4,1.8,0.4
			c0.6,0,1.2-0.1,1.6-0.3c0.4-0.2,0.9-0.5,1.4-0.8l0.4,0.4c-0.6,0.7-1.3,1.3-2.1,1.8c-0.8,0.5-1.7,0.7-2.7,0.7
			c-0.6,0-1.2-0.1-1.8-0.4s-1.1-0.7-1.6-1.1s-0.8-1-1.1-1.6c-0.3-0.6-0.4-1.2-0.4-1.9c0-0.9,0.2-1.7,0.5-2.5s0.7-1.5,1.3-2.1
			s1.2-1.1,1.9-1.4c0.7-0.3,1.6-0.5,2.4-0.5c0.3,0,0.7,0,1,0.1c0.4,0.1,0.7,0.2,1.1,0.3c0.3,0.1,0.6,0.3,0.8,0.5
			S1376.8,487.6,1376.8,487.9z"
          />
          <path
            class="st7"
            d="M1378.7,492c0-0.8,0.1-1.5,0.4-2.2s0.7-1.3,1.2-1.9c0.5-0.5,1.1-0.9,1.8-1.3c0.7-0.3,1.4-0.5,2.2-0.5
			c0.8,0,1.6,0.1,2.4,0.4c0.7,0.3,1.4,0.7,1.9,1.1s0.9,1.1,1.2,1.8c0.3,0.7,0.5,1.5,0.5,2.4c0,0.8-0.1,1.5-0.4,2.2s-0.7,1.3-1.2,1.9
			c-0.5,0.5-1.1,0.9-1.8,1.3c-0.7,0.3-1.4,0.5-2.2,0.5c-0.8,0-1.6-0.1-2.3-0.4c-0.7-0.3-1.3-0.7-1.9-1.1c-0.5-0.5-1-1.1-1.3-1.8
			C1378.8,493.7,1378.7,492.9,1378.7,492z M1380.9,491.8c0,0.5,0.1,1.1,0.2,1.7s0.4,1.2,0.6,1.7c0.3,0.5,0.7,1,1.1,1.4
			c0.5,0.4,1,0.5,1.7,0.5c0.6,0,1.1-0.2,1.6-0.5c0.4-0.3,0.8-0.7,1.1-1.2c0.3-0.5,0.5-1,0.6-1.6s0.2-1.1,0.2-1.7
			c0-0.5-0.1-1.1-0.2-1.7s-0.4-1.2-0.6-1.7s-0.7-1-1.1-1.4c-0.5-0.4-1-0.5-1.7-0.5c-0.6,0-1.1,0.2-1.6,0.5c-0.4,0.3-0.8,0.7-1.1,1.2
			c-0.3,0.5-0.5,1-0.6,1.6C1381,490.7,1380.9,491.3,1380.9,491.8z"
          />
          <path
            class="st7"
            d="M1391.6,486.6c0.5,0,0.9,0,1.4,0s0.9,0,1.4,0c0.4,0,0.7,0,1.1,0s0.7,0,1.1,0v0.6c-0.1,0-0.2,0-0.4,0
			c-0.2,0-0.3,0-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.2s-0.1,0.2-0.1,0.4c0,0.1,0.1,0.3,0.2,0.7c0.1,0.3,0.2,0.7,0.4,1.1
			c0.2,0.4,0.4,0.9,0.6,1.4s0.4,1,0.6,1.4s0.3,0.9,0.5,1.2c0.1,0.4,0.2,0.6,0.3,0.8l0,0c0.1-0.2,0.2-0.5,0.4-0.9
			c0.2-0.4,0.3-0.8,0.5-1.2c0.2-0.4,0.4-0.8,0.5-1.3c0.2-0.4,0.4-0.9,0.5-1.2s0.3-0.7,0.4-1s0.1-0.5,0.1-0.6c0-0.3,0-0.5-0.1-0.6
			s-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.3,0-0.5,0v-0.6c0.4,0,0.8,0,1.1,0c0.4,0,0.8,0,1.1,0c0.3,0,0.6,0,0.9,0
			s0.6,0,0.9,0v0.6c-0.6,0-1,0.1-1.2,0.4c-0.3,0.3-0.5,0.7-0.7,1.1l-5.5,13c-0.2,0.4-0.4,0.7-0.6,0.9c-0.3,0.2-0.6,0.3-0.9,0.3
			c-0.4,0-0.7-0.1-0.9-0.3c-0.2-0.2-0.3-0.5-0.3-0.8s0.1-0.6,0.3-0.8c0.2-0.3,0.5-0.4,0.8-0.4c0.2,0,0.4,0,0.5,0.1
			c0.1,0,0.3,0.1,0.5,0.3c0.6-1.3,1.2-2.6,1.7-3.9c-0.3-0.8-0.6-1.6-0.9-2.2c-0.2-0.6-0.5-1.3-0.8-2.1c-0.3-0.7-0.6-1.4-0.8-2.1
			c-0.3-0.7-0.5-1.2-0.7-1.7s-0.3-0.8-0.4-0.9c-0.2-0.3-0.4-0.5-0.6-0.7s-0.5-0.2-0.9-0.2L1391.6,486.6L1391.6,486.6z"
          />
          <path
            class="st7"
            d="M1414.8,496.4c-0.3,0.3-0.7,0.6-1.1,0.9s-0.8,0.4-1.3,0.4s-0.9-0.2-1.1-0.6s-0.4-0.8-0.4-1.2
			c-0.6,0.5-1.1,1-1.7,1.3s-1.3,0.5-2,0.5c-0.8,0-1.4-0.2-1.9-0.7s-0.7-1-0.7-1.7c0-0.5,0.1-0.9,0.3-1.2c0.2-0.3,0.5-0.6,1-0.9
			s1.1-0.6,1.9-0.9s1.9-0.7,3.2-1.1v-1.6c0-1.4-0.8-2.1-2.4-2.1c-0.5,0-1,0.1-1.3,0.4s-0.5,0.7-0.5,1.3c0,0.3-0.2,0.6-0.5,0.8
			s-0.6,0.3-0.9,0.3c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.2-0.1-0.3c0-0.3,0.1-0.6,0.2-0.9c0.1-0.2,0.3-0.5,0.6-0.7
			c0.2-0.2,0.5-0.4,0.9-0.6s0.7-0.4,1.2-0.6c0.2-0.1,0.4-0.2,0.6-0.3s0.3-0.2,0.5-0.2s0.3-0.1,0.5-0.2c0.2,0,0.4-0.1,0.6-0.1
			c0.3,0,0.6,0,0.9,0.1s0.7,0.2,1,0.4s0.6,0.4,0.8,0.6c0.2,0.3,0.3,0.5,0.3,0.9v6.8c0,0.5,0.1,0.9,0.2,1.1s0.4,0.3,0.6,0.3
			s0.3,0,0.5-0.1s0.3-0.2,0.4-0.3L1414.8,496.4z M1410.9,492c-0.3,0.1-0.7,0.3-1.2,0.4s-1,0.3-1.5,0.5s-0.9,0.5-1.2,0.8
			c-0.3,0.3-0.5,0.7-0.5,1.1c0,0.5,0.2,0.9,0.5,1.2c0.3,0.4,0.7,0.5,1.2,0.5c0.6,0,1.1-0.1,1.5-0.4s0.8-0.6,1.2-1V492z"
          />
          <path
            class="st7"
            d="M1289.5,523.3c0.1,0.4,0.2,0.8,0.4,1.1c0.2,0.4,0.4,0.7,0.6,0.9c0.3,0.3,0.6,0.5,0.9,0.6
			c0.4,0.2,0.7,0.2,1.2,0.2c0.4,0,0.7,0,0.9-0.1c0.3-0.1,0.5-0.2,0.6-0.4c0.2-0.2,0.3-0.4,0.3-0.6c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.5-0.1-0.9-0.4-1.2s-0.6-0.6-1-0.8s-0.8-0.4-1.3-0.6s-0.9-0.4-1.3-0.7s-0.7-0.6-1-1s-0.4-0.9-0.4-1.6c0-0.3,0.1-0.7,0.2-1.1
			c0.1-0.4,0.4-0.7,0.7-1s0.7-0.6,1.2-0.8s1.1-0.3,1.7-0.3c0.4,0,0.8,0.1,1.3,0.2c0.5,0.1,1,0.3,1.5,0.5l0.2,2.5h-0.6
			c-0.1-0.8-0.4-1.4-0.8-1.8c-0.4-0.4-1-0.6-1.8-0.6c-0.5,0-0.9,0.1-1.3,0.4c-0.4,0.3-0.6,0.7-0.6,1.2s0.1,0.9,0.4,1.1
			c0.3,0.3,0.6,0.5,1.1,0.8c0.4,0.2,0.9,0.4,1.4,0.6c0.5,0.2,0.9,0.4,1.4,0.7s0.8,0.6,1.1,1s0.4,0.9,0.4,1.6c0,0.4-0.1,0.8-0.2,1.2
			c-0.2,0.4-0.4,0.7-0.8,1.1c-0.3,0.3-0.8,0.6-1.3,0.8s-1.2,0.3-1.9,0.3c-0.5,0-1-0.1-1.5-0.2c-0.5-0.2-1-0.4-1.4-0.6l-0.3-2.8
			L1289.5,523.3z"
          />
          <path
            class="st7"
            d="M1298.3,526.1h0.5c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-6.6c0-0.3,0-0.5-0.1-0.6s-0.1-0.3-0.2-0.4
			c-0.1-0.1-0.2-0.2-0.4-0.3s-0.4-0.2-0.6-0.3v-0.4c0.3-0.1,0.6-0.2,0.9-0.3s0.6-0.2,0.8-0.3c0.3-0.1,0.5-0.2,0.6-0.2
			s0.3-0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.1s0.2,0.1,0.2,0.3v9.4c0,0.4,0.1,0.6,0.4,0.7c0.2,0.1,0.5,0.1,0.9,0.1h0.4v0.6
			c-0.4,0-0.8,0-1.3,0c-0.4,0-0.9,0-1.3,0s-0.9,0-1.3,0s-0.9,0-1.3,0L1298.3,526.1L1298.3,526.1z M1302.1,510.6
			c0,0.3-0.1,0.6-0.4,0.9c-0.2,0.2-0.5,0.4-0.8,0.4s-0.6-0.1-0.8-0.4s-0.4-0.5-0.4-0.9c0-0.3,0.1-0.6,0.4-0.8s0.5-0.4,0.8-0.4
			s0.6,0.1,0.8,0.3C1302,510,1302.1,510.2,1302.1,510.6z"
          />
          <path
            class="st7"
            d="M1315.7,524.6c0,0.2,0,0.4,0,0.6c0,0.1,0.1,0.2,0.2,0.3s0.2,0.1,0.4,0.1c0.2,0,0.4,0,0.6,0h0.4v0.5l0,0
			c-0.2,0-0.4,0.1-0.7,0.2c-0.3,0.1-0.6,0.2-1,0.3s-0.7,0.2-1,0.3s-0.5,0.1-0.5,0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.3,0-0.4v-1.1
			l0,0c-0.4,0.5-1,0.9-1.6,1.2c-0.7,0.4-1.4,0.5-2.1,0.5s-1.4-0.1-1.9-0.4c-0.6-0.3-1-0.7-1.4-1.2s-0.7-1.1-0.9-1.7
			c-0.2-0.6-0.3-1.3-0.3-2c0-0.8,0.2-1.6,0.5-2.4c0.3-0.7,0.7-1.4,1.3-2s1.2-1,1.9-1.4s1.5-0.5,2.4-0.5c0.8,0,1.6,0.1,2.3,0.4v-4.3
			c0-0.4,0-0.7-0.1-0.9c0-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.2v-0.4c0.5-0.2,1-0.3,1.4-0.5
			c0.5-0.2,0.8-0.4,1.1-0.5c0.1-0.1,0.2-0.1,0.3-0.2s0.2-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.3v16.4L1315.7,524.6L1315.7,524.6z
			M1313.7,518.6c0-0.5-0.1-0.8-0.3-1.1s-0.4-0.5-0.6-0.7c-0.3-0.2-0.5-0.3-0.8-0.4c-0.3-0.1-0.6-0.1-0.9-0.1
			c-0.4,0-0.9,0.1-1.3,0.3s-0.8,0.4-1.2,0.8c-0.3,0.4-0.6,0.8-0.8,1.4s-0.3,1.3-0.3,2.1c0,0.7,0.1,1.3,0.3,1.9
			c0.2,0.6,0.5,1.1,0.8,1.5s0.7,0.7,1.2,1s1,0.4,1.5,0.4c0.2,0,0.4,0,0.7-0.1c0.3-0.1,0.5-0.2,0.8-0.3s0.5-0.3,0.6-0.5
			c0.2-0.2,0.3-0.4,0.3-0.6L1313.7,518.6L1313.7,518.6z"
          />
          <path
            class="st7"
            d="M1318.7,526.1h0.5c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-6.6c0-0.3,0-0.5-0.1-0.6s-0.1-0.3-0.2-0.4
			s-0.2-0.2-0.4-0.3s-0.4-0.2-0.6-0.3v-0.4c0.3-0.1,0.6-0.2,0.9-0.3s0.6-0.2,0.8-0.3c0.3-0.1,0.5-0.2,0.6-0.2
			c0.2-0.1,0.3-0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.1s0.2,0.1,0.2,0.3v9.4c0,0.4,0.1,0.6,0.4,0.7c0.2,0.1,0.5,0.1,0.9,0.1h0.4v0.6
			c-0.4,0-0.8,0-1.3,0c-0.4,0-0.9,0-1.3,0s-0.9,0-1.3,0s-0.9,0-1.3,0L1318.7,526.1L1318.7,526.1z M1322.5,510.6
			c0,0.3-0.1,0.6-0.4,0.9s-0.5,0.4-0.8,0.4s-0.6-0.1-0.8-0.4s-0.4-0.5-0.4-0.9c0-0.3,0.1-0.6,0.4-0.8c0.2-0.2,0.5-0.4,0.8-0.4
			s0.6,0.1,0.8,0.3C1322.4,510,1322.5,510.2,1322.5,510.6z"
          />
          <path
            class="st7"
            d="M1327,518.8c0-0.2,0-0.4-0.1-0.6c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.2-0.7-0.3
			v-0.5c0.5-0.2,1-0.4,1.6-0.5c0.5-0.2,1.1-0.4,1.7-0.6c0.1,0,0.1,0.1,0.1,0.2v1.6c0.7-0.5,1.4-0.9,2-1.3c0.7-0.4,1.4-0.5,2.2-0.5
			c0.5,0,1,0.1,1.3,0.3c0.4,0.2,0.6,0.4,0.9,0.7c0.2,0.3,0.4,0.7,0.5,1.1c0.1,0.4,0.1,0.9,0.1,1.3v6.3c0,0.4,0.1,0.6,0.4,0.7
			c0.2,0.1,0.5,0.1,0.9,0.1h0.4v0.6c-0.4,0-0.9,0-1.3,0s-0.9,0-1.3,0s-0.9,0-1.3,0s-0.9,0-1.3,0v-0.6h0.4c0.3,0,0.6,0,0.8-0.1
			c0.2-0.1,0.3-0.3,0.3-0.7V519c0-0.7-0.2-1.2-0.6-1.6c-0.4-0.3-0.9-0.5-1.6-0.5c-0.2,0-0.4,0-0.7,0.1c-0.2,0.1-0.5,0.2-0.7,0.3
			c-0.3,0.1-0.5,0.2-0.8,0.4c-0.3,0.1-0.5,0.3-0.7,0.4v7.2c0,0.4,0.1,0.6,0.4,0.7c0.2,0.1,0.5,0.1,0.9,0.1h0.4v0.6
			c-0.4,0-0.9,0-1.3,0s-0.9,0-1.3,0s-0.9,0-1.3,0s-0.9,0-1.3,0v-0.6h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7L1327,518.8
			L1327,518.8z"
          />
          <path
            class="st7"
            d="M1349.9,517.4l-2.4-0.1c0.2,0.3,0.4,0.6,0.5,0.9c0.1,0.3,0.2,0.7,0.2,1c0,0.4-0.1,0.9-0.3,1.3
			s-0.5,0.9-0.8,1.2c-0.4,0.4-0.8,0.7-1.4,0.9s-1.2,0.3-2,0.3c-0.4,0-0.9,0-1.3-0.1c-0.2,0.2-0.4,0.4-0.5,0.5
			c-0.1,0.2-0.2,0.4-0.2,0.7c0,0.1,0,0.2,0,0.3s0.1,0.2,0.2,0.4s0.3,0.2,0.5,0.3s0.5,0.1,1,0.1c0.6,0,1.2,0,1.8,0c0.6,0,1.1,0,1.6,0
			c1,0,1.9,0.2,2.5,0.5c0.6,0.4,0.9,1,0.9,1.9c0,0.2-0.1,0.6-0.3,1.1c-0.2,0.5-0.5,1-1,1.6s-1.1,1-2,1.4c-0.8,0.4-1.9,0.6-3.2,0.6
			c-0.8,0-1.5-0.1-2-0.2s-1.1-0.4-1.4-0.6s-0.7-0.6-0.9-0.9s-0.3-0.7-0.3-1c0-0.4,0.1-0.7,0.2-1.1s0.4-0.6,0.6-0.8
			c0.3-0.2,0.6-0.4,0.9-0.6c0.3-0.2,0.7-0.3,1.1-0.4l0,0c-0.3-0.1-0.5-0.2-0.8-0.3s-0.5-0.3-0.7-0.5s-0.4-0.4-0.5-0.6
			c-0.1-0.2-0.2-0.5-0.2-0.8c0-0.1,0-0.3,0-0.4c0-0.1,0-0.3,0.1-0.4c0.3-0.2,0.7-0.4,1.1-0.6c0.4-0.2,0.7-0.4,0.9-0.6
			c-0.7-0.4-1.2-0.8-1.7-1.3s-0.6-1.2-0.6-2c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.5-0.9,0.9-1.2s0.9-0.6,1.4-0.8c0.6-0.2,1.2-0.3,2-0.3
			c0.4,0,0.8,0,1.1,0.1c0.4,0.1,0.7,0.2,1,0.2c0.3,0.1,0.7,0.2,1.1,0.3s0.8,0.1,1.3,0.1c0.2,0,0.4,0,0.6,0s0.5,0,0.8-0.1
			L1349.9,517.4L1349.9,517.4z M1348.2,528.7c0-0.4-0.1-0.8-0.4-1.1s-0.5-0.5-0.9-0.6s-0.7-0.2-1.1-0.3c-0.4,0-0.8-0.1-1.1-0.1
			c-0.4,0-0.8,0-1.3,0s-0.9,0.1-1.3,0.3c-0.4,0.1-0.7,0.4-1,0.7s-0.4,0.8-0.4,1.3c0,0.4,0.1,0.8,0.3,1.1c0.2,0.3,0.4,0.6,0.7,0.8
			s0.6,0.4,1,0.5s0.8,0.1,1.2,0.1c0.4,0,0.8,0,1.3-0.1s1-0.2,1.4-0.5c0.4-0.2,0.8-0.5,1.1-0.9C1348,529.7,1348.2,529.2,1348.2,528.7
			z M1346.2,519.4c0-0.4-0.1-0.8-0.2-1.1c-0.1-0.4-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.7s-0.7-0.3-1.1-0.3s-0.7,0.1-1,0.3
			s-0.5,0.4-0.7,0.7s-0.3,0.6-0.4,0.9c-0.1,0.3-0.1,0.7-0.1,1c0,0.4,0,0.7,0.1,1.1c0.1,0.4,0.2,0.7,0.4,1.1c0.2,0.3,0.4,0.6,0.7,0.8
			c0.3,0.2,0.7,0.3,1.1,0.3s0.8-0.1,1.1-0.2s0.6-0.4,0.7-0.6s0.3-0.6,0.4-0.9C1346.1,520.1,1346.2,519.8,1346.2,519.4z"
          />
        </g>
      </g>
      <g id="raised_punto" v-bind:class="{'opac':over == 'raised'}">
        <circle class="st6" cx="636.8" cy="414.7" r="25" />
      </g>
      <g id="raised_arrow" v-bind:class="{'opac':over != 'raised'}">
        <path
          class="st7"
          d="M644.7,467.5c-0.3-1.2-1.4-4-1.6-4.2s-1.2-3.1-1.3-3.4s-0.8-2-1.1-3.1s-0.7-1.8-0.9-2.6
		c-0.2-0.8-0.2-0.8-0.6-1.8c-0.3-1-0.3-1.3-0.7-1.8c-0.4-0.6-0.8-1.3-1.3-1.7s-0.1-0.9-0.8-0.8c-0.8,0.1-2.5,2-2.9,2.6
		s-1.7,2-2.1,2.2c-0.3,0.3-1.5,2.2-1.9,2.7s-1.5,2.9-1.7,3.1c-0.3,0.3-2,3.3-2,3.3s-2.2,4.1-2.3,4.3s-0.7,1.7-0.7,1.7l-0.6,1.7
		c0,0-0.3,1-0.5,1.3s-1,1.4-1,1.4s-0.7,1.1-0.8,1.5s-0.8,0.8-0.4,1.2c0.3,0.3,0.7,0.7,1.1,0.7c0.4,0,0.2,0.4,0.9,0s0.6-0.3,1.1-0.8
		s1.3-1.7,1.5-1.7s1.3-1,1.3-1s1.3-1.5,1.5-1.8s1.6-2.6,1.6-2.6s0.6-1.8,0.8-2c0.2-0.1,0.8-1.4,1-1.6s0.5-1.3,0.9-1.7
		c0.4-0.4,0.9-1.1,1-1.4c0-0.3,0.4-1.6,0.5-1.8c0.1-0.2,0.6-0.9,0.6-0.9l0.1-0.1l-0.1,1.3l-0.7,5.5l-0.4,3.9l-0.8,5.3l-0.1,4.7
		l-0.4,2.7l-0.7,5.5l-0.3,7.2l-0.4,4.7v5.4l-0.3,2.7l-0.1,10.1l-0.2,2.7l-0.1,3.5l0.3,6.8l0.1,2.4v4.8l0.1,3.2l0.4,2l0.1,2.7
		c0,0,0.1,3.7,0.2,3.9c0.1,0.2,0.2,2.4,0.2,2.4l0.1,2.3c0,0,0,0.6,0.2,0.9c0.2,0.3,0.8,0.3,0.8,0.3c0.5-0.1,0.6-0.2,0.7-0.4
		s0.4,0.1,0.3-0.6c-0.1-0.6-0.5-2.5-0.5-2.5s0.1-3.6,0.1-4s-0.3-0.6-0.3-1s-0.1-9.3-0.1-9.3s-0.6-2.2-0.5-2.4s0.1-0.1,0-1
		c-0.1-0.8,0.1-15.6,0.1-16.1c-0.1-0.5,0.1-6.6,0.1-6.8s0-1.1,0.1-1.5c0.1-0.3,0.1-0.9,0.1-0.9l0.3-7.9c0,0-0.1-2.1,0.1-2.2
		c0.2-0.1,0.4-5.8,0.4-5.8s0.4-5.7,0.4-5.9s0.7-6.1,0.7-6.1l0.3-5.4l0.3-4.5l1-6.6l0.4-4.3l0.6-4.3l0.6-2.9l0.3,0.3l0.4,0.4l0.7,2.2
		l0.8,2.7l0.9,2.3l1.1,3.3c0,0,1.2,2.9,1.4,3.1s0.9,2.4,1.5,2.6s0.8,0.3,1.2,0S645,468.7,644.7,467.5z M632.1,456.5
		c0,0-0.8,1.5-0.9,1.7c-0.1,0.2-0.8,1.7-0.8,1.7s-0.8,1-0.9,1.3c-0.1,0.2-0.1,0.3-0.3,0.8c-0.1,0.5-0.6,1.6-0.6,1.6l-0.6,1l-0.5,0.6
		c0,0-0.1,0.1-0.3,0.5c-0.3,0.4-0.3,0.7-0.6,1.1s-0.6,0.6-0.9,1s-0.8,1-1,1.3s-0.8,1-0.8,1s-0.2-0.6,0-0.9s0.6-0.8,0.8-1.3
		s0.2-0.8,0.6-1.3c0.4-0.5,1-1.7,1.3-2.2c0.3-0.4,0.7-1.3,0.9-1.6c0.2-0.3,0.1-0.6,0.6-1.3c0.4-0.6,0.6-1,0.8-1.4s0.6-1.2,0.8-1.4
		c0.2-0.2,0.6-0.8,0.8-1.1c0.2-0.3,0.6-1.3,0.8-1.5c0.1-0.2,0.2-0.5,0.5-0.7s0.3-0.6,0.6-0.8c0.3-0.2,1.3-1.2,1.3-1.2l-0.1,1.1
		L632.1,456.5z"
        />
        <g>
          <path
            class="st7"
            d="M665.8,516.3v5.6c0,0.4,0.1,0.7,0.2,1c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.4,0.2,0.7,0.2s0.6,0,0.9,0v0.6
			c-0.6,0-1.1,0-1.7,0c-0.5,0-1.1,0-1.6,0c-0.6,0-1.2,0-1.7,0s-1.2,0-1.8,0v-0.6c0.3,0,0.6,0,0.9,0c0.3,0,0.5-0.1,0.7-0.2
			c0.2-0.1,0.4-0.3,0.5-0.5s0.2-0.5,0.2-0.8v-12.2c0-0.4-0.1-0.7-0.2-1c-0.1-0.2-0.3-0.4-0.5-0.5s-0.4-0.2-0.7-0.2s-0.6,0-0.9,0
			v-0.6c0.7,0,1.3,0,1.9,0c0.6,0,1.2,0,1.8,0c0.4,0,0.9,0,1.4,0s1.1,0,1.9,0c1.1,0,2.1,0.1,2.8,0.4s1.3,0.6,1.7,1.1
			c0.4,0.4,0.7,0.9,0.8,1.4c0.2,0.5,0.2,1,0.2,1.4c0,0.5-0.1,1-0.3,1.4c-0.2,0.4-0.4,0.8-0.7,1.2s-0.7,0.7-1.1,0.9
			c-0.4,0.2-0.9,0.4-1.3,0.6c1.1,1.6,2,2.9,2.6,3.9c0.7,1,1.2,1.8,1.8,2.4c0.4,0.4,0.8,0.8,1.2,1.1c0.4,0.3,0.9,0.4,1.5,0.4v0.6
			c-0.3,0-0.6,0-0.9,0c-0.3,0-0.6,0-0.9,0c-0.3,0-0.7,0-1,0s-0.7,0-1,0c-1.1-1.2-2.1-2.5-2.9-3.9c-0.9-1.4-1.7-2.7-2.6-4.1
			L665.8,516.3L665.8,516.3z M671.2,511.9c0-0.5-0.1-1-0.2-1.5c-0.1-0.4-0.4-0.8-0.7-1.1c-0.3-0.3-0.7-0.6-1.2-0.8
			c-0.5-0.2-1.1-0.3-1.8-0.3c-0.3,0-0.6,0-0.8,0s-0.4,0.1-0.7,0.1v7.1c0.4,0.1,0.8,0.1,1.3,0.1c0.7,0,1.4-0.1,1.9-0.3
			s0.9-0.5,1.3-0.9c0.3-0.3,0.6-0.7,0.7-1.2C671.2,512.7,671.2,512.3,671.2,511.9z"
          />
          <path
            class="st7"
            d="M680.6,517.6c0,0.1,0,0.3,0,0.5s0,0.5,0,0.9c0,0.6,0.1,1.1,0.3,1.6s0.4,1,0.8,1.3c0.3,0.4,0.7,0.7,1.1,0.9
			c0.4,0.2,0.9,0.3,1.4,0.3c0.7,0,1.3-0.2,1.8-0.5s1-0.7,1.5-1.1l0.3,0.3c-0.3,0.4-0.6,0.7-0.9,1.1c-0.3,0.4-0.7,0.6-1,0.9
			c-0.4,0.2-0.8,0.4-1.2,0.6c-0.4,0.1-0.9,0.2-1.4,0.2c-1,0-1.7-0.2-2.3-0.6c-0.6-0.4-1.1-0.9-1.4-1.4c-0.3-0.6-0.6-1.1-0.7-1.7
			c-0.1-0.6-0.2-1.1-0.2-1.5c0-1.2,0.2-2.2,0.5-2.9c0.3-0.8,0.8-1.4,1.3-1.9s1-0.8,1.6-1s1.1-0.3,1.5-0.3c0.5,0,1,0.1,1.4,0.2
			c0.5,0.2,0.9,0.4,1.3,0.7c0.4,0.3,0.7,0.7,1,1.2s0.4,1,0.4,1.7c0,0.1,0,0.2,0,0.2s0,0.1,0,0.2l-0.2,0.1L680.6,517.6L680.6,517.6z
			M684.9,516.9c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.1-0.3,0.1-0.4c0-0.6-0.2-1.1-0.5-1.6c-0.4-0.4-0.9-0.7-1.6-0.7
			c-0.7,0-1.4,0.3-1.8,0.8c-0.5,0.5-0.8,1.2-0.9,2.1H684.9L684.9,516.9z"
          />
          <path
            class="st7"
            d="M698.4,514.9c0,0.3-0.1,0.5-0.2,0.7c-0.2,0.2-0.4,0.3-0.7,0.3s-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.5-0.7
			c-0.2-0.2-0.5-0.5-0.8-0.7s-0.8-0.3-1.3-0.3s-1,0.1-1.4,0.4s-0.7,0.6-1,1c-0.3,0.4-0.5,0.9-0.6,1.4s-0.2,1-0.2,1.4
			c0,0.6,0.1,1.2,0.3,1.8s0.5,1.1,0.8,1.5c0.4,0.4,0.8,0.8,1.3,1c0.5,0.3,1.1,0.4,1.8,0.4c0.6,0,1.1-0.1,1.6-0.3
			c0.4-0.2,0.9-0.5,1.4-0.8l0.4,0.4c-0.6,0.7-1.3,1.3-2.1,1.8s-1.7,0.7-2.6,0.7c-0.6,0-1.2-0.1-1.8-0.4s-1.1-0.7-1.5-1.1
			c-0.4-0.5-0.8-1-1.1-1.6c-0.3-0.6-0.4-1.2-0.4-1.9c0-0.9,0.1-1.7,0.4-2.5c0.3-0.8,0.7-1.5,1.2-2c0.5-0.6,1.2-1,1.9-1.4
			c0.7-0.3,1.5-0.5,2.4-0.5c0.3,0,0.6,0,1,0.1c0.4,0.1,0.7,0.1,1.1,0.3c0.3,0.1,0.6,0.3,0.8,0.5
			C698.3,514.3,698.4,514.6,698.4,514.9z"
          />
          <path
            class="st7"
            d="M699.7,513.6c0.5,0,0.9,0,1.4,0s0.9,0,1.4,0c0.4,0,0.7,0,1.1,0c0.4,0,0.7,0,1.1,0v0.6c-0.1,0-0.2,0-0.4,0
			s-0.3,0-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0.1,0.3,0.2,0.7c0.1,0.3,0.2,0.7,0.4,1.1
			c0.2,0.4,0.4,0.9,0.6,1.4c0.2,0.5,0.4,0.9,0.6,1.4c0.2,0.4,0.3,0.8,0.5,1.2c0.1,0.4,0.2,0.6,0.3,0.8l0,0c0.1-0.2,0.2-0.5,0.4-0.9
			c0.2-0.4,0.3-0.8,0.5-1.2s0.4-0.8,0.5-1.3s0.4-0.8,0.5-1.2s0.3-0.7,0.4-1c0.1-0.3,0.1-0.5,0.1-0.6c0-0.3,0-0.5-0.1-0.6
			c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.3,0-0.5,0v-0.6c0.4,0,0.7,0,1.1,0c0.4,0,0.8,0,1.1,0s0.6,0,0.9,0
			c0.3,0,0.6,0,0.9,0v0.6c-0.6,0-1,0.1-1.2,0.4s-0.5,0.7-0.7,1.1l-5.5,12.8c-0.2,0.4-0.4,0.7-0.6,0.9c-0.2,0.2-0.6,0.3-0.9,0.3
			c-0.4,0-0.7-0.1-0.9-0.3c-0.2-0.2-0.3-0.5-0.3-0.8s0.1-0.6,0.3-0.8c0.2-0.3,0.5-0.4,0.8-0.4c0.2,0,0.4,0,0.5,0.1
			c0.1,0,0.3,0.1,0.5,0.3c0.6-1.3,1.2-2.6,1.7-3.9c-0.3-0.8-0.6-1.5-0.8-2.2c-0.2-0.6-0.5-1.3-0.8-2s-0.6-1.4-0.8-2.1
			c-0.3-0.6-0.5-1.2-0.7-1.7s-0.3-0.7-0.4-0.8c-0.2-0.3-0.4-0.5-0.6-0.7c-0.2-0.2-0.5-0.2-0.9-0.2L699.7,513.6L699.7,513.6z"
          />
          <path
            class="st7"
            d="M721.2,514.9c0,0.3-0.1,0.5-0.2,0.7c-0.2,0.2-0.4,0.3-0.7,0.3s-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.5-0.7
			c-0.2-0.2-0.5-0.5-0.8-0.7s-0.8-0.3-1.3-0.3s-1,0.1-1.4,0.4s-0.7,0.6-1,1c-0.3,0.4-0.5,0.9-0.6,1.4s-0.2,1-0.2,1.4
			c0,0.6,0.1,1.2,0.3,1.8s0.5,1.1,0.8,1.5c0.4,0.4,0.8,0.8,1.3,1c0.5,0.3,1.1,0.4,1.8,0.4c0.6,0,1.1-0.1,1.6-0.3
			c0.4-0.2,0.9-0.5,1.4-0.8l0.4,0.4c-0.6,0.7-1.3,1.3-2.1,1.8s-1.7,0.7-2.6,0.7c-0.6,0-1.2-0.1-1.8-0.4s-1.1-0.7-1.5-1.1
			c-0.4-0.5-0.8-1-1.1-1.6c-0.3-0.6-0.4-1.2-0.4-1.9c0-0.9,0.1-1.7,0.4-2.5c0.3-0.8,0.7-1.5,1.2-2c0.5-0.6,1.2-1,1.9-1.4
			c0.7-0.3,1.5-0.5,2.4-0.5c0.3,0,0.6,0,1,0.1c0.4,0.1,0.7,0.1,1.1,0.3c0.3,0.1,0.6,0.3,0.8,0.5
			C721.1,514.3,721.2,514.6,721.2,514.9z"
          />
          <path
            class="st7"
            d="M722.8,523.7h0.5c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-13.4c0-0.4,0-0.7-0.1-0.9
			c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.2v-0.4c0.5-0.2,1-0.3,1.4-0.5c0.5-0.2,0.8-0.4,1.1-0.5
			c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.3v16.7c0,0.4,0.1,0.6,0.4,0.7c0.2,0.1,0.5,0.1,0.9,0.1h0.4
			v0.6c-0.4,0-0.8,0-1.3,0c-0.4,0-0.8,0-1.3,0s-0.9,0-1.3,0s-0.9,0-1.3,0L722.8,523.7L722.8,523.7z"
          />
          <path
            class="st7"
            d="M731.5,517.6c0,0.1,0,0.3,0,0.5s0,0.5,0,0.9c0,0.6,0.1,1.1,0.3,1.6s0.4,1,0.8,1.3c0.3,0.4,0.7,0.7,1.1,0.9
			c0.4,0.2,0.9,0.3,1.4,0.3c0.7,0,1.3-0.2,1.8-0.5s1-0.7,1.5-1.1l0.3,0.3c-0.3,0.4-0.6,0.7-0.9,1.1c-0.3,0.4-0.7,0.6-1,0.9
			c-0.4,0.2-0.8,0.4-1.2,0.6c-0.4,0.1-0.9,0.2-1.4,0.2c-1,0-1.7-0.2-2.3-0.6c-0.6-0.4-1.1-0.9-1.4-1.4c-0.3-0.6-0.6-1.1-0.7-1.7
			c-0.1-0.6-0.2-1.1-0.2-1.5c0-1.2,0.2-2.2,0.5-2.9c0.3-0.8,0.8-1.4,1.3-1.9s1-0.8,1.6-1s1.1-0.3,1.5-0.3c0.5,0,1,0.1,1.4,0.2
			c0.5,0.2,0.9,0.4,1.3,0.7c0.4,0.3,0.7,0.7,1,1.2s0.4,1,0.4,1.7c0,0.1,0,0.2,0,0.2s0,0.1,0,0.2l-0.2,0.1L731.5,517.6L731.5,517.6z
			M735.8,516.9c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.1-0.3,0.1-0.4c0-0.6-0.2-1.1-0.5-1.6c-0.4-0.4-0.9-0.7-1.6-0.7
			c-0.7,0-1.4,0.3-1.8,0.8c-0.5,0.5-0.8,1.2-0.9,2.1H735.8L735.8,516.9z"
          />
          <path
            class="st7"
            d="M750.7,522.2c0,0.2,0,0.4,0,0.6s0.1,0.2,0.2,0.3s0.2,0.1,0.4,0.1s0.4,0,0.6,0h0.4v0.5l0,0
			c-0.2,0-0.4,0.1-0.7,0.2c-0.3,0.1-0.6,0.2-1,0.3c-0.4,0.1-0.7,0.2-1,0.3c-0.3,0.1-0.5,0.1-0.5,0.1c-0.1,0-0.2-0.1-0.2-0.2
			c0-0.1,0-0.3,0-0.4v-1.1l0,0c-0.4,0.5-1,0.9-1.6,1.2c-0.6,0.4-1.3,0.5-2.1,0.5c-0.7,0-1.4-0.1-1.9-0.4c-0.6-0.3-1-0.7-1.4-1.2
			s-0.7-1.1-0.9-1.7c-0.2-0.6-0.3-1.3-0.3-2c0-0.8,0.2-1.6,0.5-2.3c0.3-0.7,0.7-1.4,1.3-2c0.5-0.6,1.2-1,1.9-1.4
			c0.7-0.3,1.5-0.5,2.3-0.5s1.6,0.1,2.3,0.4v-4.3c0-0.4,0-0.7-0.1-0.9c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.3
			c-0.2-0.1-0.4-0.1-0.6-0.2v-0.4c0.5-0.2,1-0.3,1.4-0.5c0.5-0.2,0.8-0.4,1.1-0.5c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.2-0.1
			c0.1,0,0.2,0.1,0.2,0.3v16.3H750.7L750.7,522.2z M748.7,516.3c0-0.5-0.1-0.8-0.3-1.1c-0.2-0.3-0.4-0.5-0.6-0.7s-0.5-0.3-0.8-0.3
			s-0.6-0.1-0.8-0.1c-0.4,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.8,0.4-1.1,0.8c-0.3,0.4-0.6,0.8-0.8,1.4c-0.2,0.6-0.3,1.3-0.3,2.1
			c0,0.7,0.1,1.3,0.3,1.8c0.2,0.6,0.5,1.1,0.8,1.5c0.3,0.4,0.7,0.7,1.2,1s1,0.3,1.5,0.3c0.2,0,0.4,0,0.7-0.1
			c0.3-0.1,0.5-0.2,0.8-0.3c0.2-0.1,0.5-0.3,0.6-0.4c0.2-0.2,0.3-0.4,0.3-0.6v-5.6L748.7,516.3L748.7,516.3z"
          />
          <path
            class="st7"
            d="M670.3,541.9c0,0.3-0.1,0.5-0.2,0.7c-0.2,0.2-0.4,0.3-0.7,0.3s-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.5-0.7
			c-0.2-0.2-0.5-0.5-0.8-0.7s-0.8-0.3-1.3-0.3s-1,0.1-1.4,0.4c-0.4,0.3-0.7,0.6-1,1s-0.5,0.9-0.6,1.4s-0.2,1-0.2,1.4
			c0,0.6,0.1,1.2,0.3,1.8s0.5,1.1,0.8,1.5c0.4,0.4,0.8,0.8,1.3,1c0.5,0.3,1.1,0.4,1.8,0.4c0.6,0,1.1-0.1,1.6-0.3
			c0.4-0.2,0.9-0.5,1.4-0.8l0.4,0.4c-0.6,0.7-1.3,1.3-2.1,1.8s-1.7,0.7-2.6,0.7c-0.6,0-1.2-0.1-1.8-0.4c-0.6-0.3-1.1-0.7-1.5-1.1
			c-0.4-0.5-0.8-1-1.1-1.6s-0.4-1.2-0.4-1.9c0-0.9,0.1-1.7,0.4-2.5c0.3-0.8,0.7-1.5,1.2-2c0.5-0.6,1.2-1,1.9-1.4
			c0.7-0.3,1.5-0.5,2.4-0.5c0.3,0,0.6,0,1,0.1s0.7,0.1,1.1,0.3c0.3,0.1,0.6,0.3,0.8,0.5C670.1,541.3,670.3,541.6,670.3,541.9z"
          />
          <path
            class="st7"
            d="M672.1,546c0-0.8,0.1-1.5,0.4-2.2c0.3-0.7,0.7-1.3,1.1-1.8c0.5-0.5,1.1-0.9,1.8-1.2s1.4-0.5,2.2-0.5
			s1.6,0.1,2.3,0.4c0.7,0.3,1.3,0.6,1.9,1.1c0.5,0.5,0.9,1.1,1.2,1.8s0.4,1.5,0.4,2.3s-0.1,1.5-0.4,2.2c-0.3,0.7-0.7,1.3-1.2,1.8
			s-1.1,0.9-1.8,1.2s-1.4,0.5-2.2,0.5s-1.6-0.1-2.3-0.4c-0.7-0.3-1.3-0.6-1.9-1.1c-0.5-0.5-0.9-1.1-1.3-1.8S672.1,546.8,672.1,546z
			M674.4,545.7c0,0.5,0.1,1.1,0.2,1.7c0.1,0.6,0.4,1.2,0.6,1.7c0.3,0.5,0.7,1,1.1,1.3c0.5,0.4,1,0.5,1.6,0.5s1.1-0.2,1.5-0.5
			s0.8-0.7,1.1-1.2s0.5-1,0.6-1.6s0.2-1.1,0.2-1.6s-0.1-1.1-0.2-1.7c-0.1-0.6-0.4-1.2-0.6-1.7c-0.3-0.5-0.7-1-1.1-1.3
			c-0.5-0.4-1-0.5-1.7-0.5c-0.6,0-1.1,0.2-1.5,0.5s-0.8,0.7-1,1.2c-0.3,0.5-0.5,1-0.6,1.6C674.4,544.7,674.4,545.2,674.4,545.7z"
          />
          <path
            class="st7"
            d="M686.8,543.4c0-0.2,0-0.4-0.1-0.6c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.3s-0.4-0.2-0.7-0.3v-0.5
			c0.5-0.2,1-0.3,1.5-0.5s1.1-0.4,1.6-0.6c0.1,0,0.1,0.1,0.1,0.2v1.6c0.7-0.5,1.4-0.9,2-1.3c0.6-0.4,1.4-0.5,2.2-0.5
			c0.5,0,0.9,0.1,1.3,0.3c0.4,0.2,0.6,0.4,0.9,0.7c0.2,0.3,0.4,0.7,0.5,1.1c0.1,0.4,0.1,0.8,0.1,1.3v6.2c0,0.4,0.1,0.6,0.4,0.7
			c0.2,0.1,0.5,0.1,0.9,0.1h0.4v0.6c-0.4,0-0.8,0-1.3,0c-0.4,0-0.9,0-1.3,0s-0.9,0-1.3,0s-0.9,0-1.3,0v-0.6h0.4c0.3,0,0.6,0,0.8-0.1
			s0.3-0.3,0.3-0.7v-6.3c0-0.7-0.2-1.2-0.6-1.5s-0.9-0.5-1.6-0.5c-0.2,0-0.4,0-0.7,0.1c-0.2,0.1-0.5,0.2-0.7,0.3s-0.5,0.2-0.7,0.4
			c-0.2,0.1-0.5,0.3-0.7,0.4v7.1c0,0.4,0.1,0.6,0.4,0.7c0.2,0.1,0.5,0.1,0.9,0.1h0.4v0.6c-0.4,0-0.8,0-1.3,0c-0.4,0-0.9,0-1.3,0
			s-0.9,0-1.3,0s-0.9,0-1.3,0v-0.6h0.4c0.3,0,0.6,0,0.8-0.1s0.3-0.3,0.3-0.7v-6.4H686.8z"
          />
          <path
            class="st7"
            d="M707.8,541.9c0,0.3-0.1,0.5-0.2,0.7c-0.2,0.2-0.4,0.3-0.7,0.3s-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.5-0.7
			c-0.2-0.2-0.5-0.5-0.8-0.7s-0.8-0.3-1.3-0.3s-1,0.1-1.4,0.4c-0.4,0.3-0.7,0.6-1,1s-0.5,0.9-0.6,1.4s-0.2,1-0.2,1.4
			c0,0.6,0.1,1.2,0.3,1.8s0.5,1.1,0.8,1.5c0.4,0.4,0.8,0.8,1.3,1c0.5,0.3,1.1,0.4,1.8,0.4c0.6,0,1.1-0.1,1.6-0.3
			c0.4-0.2,0.9-0.5,1.4-0.8l0.4,0.4c-0.6,0.7-1.3,1.3-2.1,1.8s-1.7,0.7-2.6,0.7c-0.6,0-1.2-0.1-1.8-0.4c-0.6-0.3-1.1-0.7-1.5-1.1
			c-0.4-0.5-0.8-1-1.1-1.6s-0.4-1.2-0.4-1.9c0-0.9,0.1-1.7,0.4-2.5c0.3-0.8,0.7-1.5,1.2-2c0.5-0.6,1.2-1,1.9-1.4
			c0.7-0.3,1.5-0.5,2.4-0.5c0.3,0,0.6,0,1,0.1s0.7,0.1,1.1,0.3c0.3,0.1,0.6,0.3,0.8,0.5C707.7,541.3,707.8,541.6,707.8,541.9z"
          />
          <path
            class="st7"
            d="M709.3,550.6h0.7c0.3,0,0.6,0,0.8-0.1s0.3-0.3,0.3-0.7V543c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.2-0.3-0.3
			s-0.3-0.1-0.4-0.2c-0.1,0-0.3-0.1-0.4-0.1v-0.4c0.9-0.4,1.6-0.7,2.1-1s0.8-0.4,0.9-0.4c0.1,0,0.2,0.1,0.2,0.3v2.2l0,0
			c0.1-0.2,0.3-0.5,0.5-0.8s0.4-0.6,0.7-0.8c0.2-0.3,0.5-0.5,0.8-0.6s0.7-0.3,1-0.3s0.6,0.1,0.9,0.3s0.5,0.5,0.5,1
			c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.2-0.5,0.4-0.8,0.4c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.3-0.2
			c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.4,0.1s-0.4,0.2-0.6,0.3c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,0.3-0.2,0.6-0.2,0.9v5.7
			c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h1.4v0.6c-0.7,0-1.3,0-1.8,0s-1.1,0-1.6,0s-1,0-1.5,0s-1,0-1.4,0v-0.5L709.3,550.6
			L709.3,550.6z"
          />
          <path
            class="st7"
            d="M720.8,544.6c0,0.1,0,0.3,0,0.5s0,0.5,0,0.9c0,0.6,0.1,1.1,0.3,1.6s0.4,1,0.8,1.3c0.3,0.4,0.7,0.7,1.1,0.9
			c0.4,0.2,0.9,0.3,1.4,0.3c0.7,0,1.3-0.2,1.8-0.5s1-0.7,1.5-1.1l0.3,0.3c-0.3,0.4-0.6,0.7-0.9,1.1s-0.7,0.6-1,0.9
			c-0.4,0.2-0.8,0.4-1.2,0.6c-0.4,0.1-0.9,0.2-1.4,0.2c-1,0-1.7-0.2-2.3-0.6c-0.6-0.4-1.1-0.9-1.4-1.4c-0.3-0.6-0.6-1.1-0.7-1.7
			c-0.1-0.6-0.2-1.1-0.2-1.5c0-1.2,0.2-2.2,0.5-2.9c0.3-0.8,0.8-1.4,1.3-1.9s1-0.8,1.6-1s1.1-0.3,1.5-0.3c0.5,0,1,0.1,1.4,0.2
			c0.5,0.2,0.9,0.4,1.3,0.7c0.4,0.3,0.7,0.7,1,1.2s0.4,1,0.4,1.7c0,0.1,0,0.2,0,0.2s0,0.1,0,0.2l-0.2,0.1H720.8z M725.1,543.8
			c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.1-0.3,0.1-0.4c0-0.6-0.2-1.1-0.5-1.6c-0.4-0.4-0.9-0.7-1.6-0.7c-0.7,0-1.4,0.3-1.8,0.8
			c-0.5,0.5-0.8,1.2-0.9,2.1L725.1,543.8L725.1,543.8z"
          />
          <path
            class="st7"
            d="M732.8,548.3c0,0.4,0,0.7,0.1,1s0.2,0.5,0.4,0.6c0.2,0.2,0.3,0.3,0.5,0.3s0.3,0.1,0.5,0.1
			c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.8-0.5l0.4,0.4c-0.4,0.4-0.9,0.8-1.4,1.1s-1.1,0.5-1.8,0.5c-0.8,0-1.3-0.2-1.7-0.6
			c-0.4-0.4-0.6-0.9-0.6-1.7v-7.4h-1.6v-0.4c0.3-0.2,0.6-0.5,0.9-0.7s0.5-0.4,0.8-0.7s0.5-0.5,0.8-0.8s0.5-0.7,0.8-1.2h0.3v2.5h3.6
			v1.3h-3.6v6.4L732.8,548.3L732.8,548.3z"
          />
          <path
            class="st7"
            d="M739.6,544.6c0,0.1,0,0.3,0,0.5s0,0.5,0,0.9c0,0.6,0.1,1.1,0.3,1.6s0.4,1,0.8,1.3c0.3,0.4,0.7,0.7,1.1,0.9
			c0.4,0.2,0.9,0.3,1.4,0.3c0.7,0,1.3-0.2,1.8-0.5s1-0.7,1.5-1.1l0.3,0.3c-0.3,0.4-0.6,0.7-0.9,1.1s-0.7,0.6-1,0.9
			c-0.4,0.2-0.8,0.4-1.2,0.6c-0.4,0.1-0.9,0.2-1.4,0.2c-1,0-1.7-0.2-2.3-0.6c-0.6-0.4-1.1-0.9-1.4-1.4c-0.3-0.6-0.6-1.1-0.7-1.7
			c-0.1-0.6-0.2-1.1-0.2-1.5c0-1.2,0.2-2.2,0.5-2.9c0.3-0.8,0.8-1.4,1.3-1.9s1-0.8,1.6-1s1.1-0.3,1.5-0.3c0.5,0,1,0.1,1.4,0.2
			c0.5,0.2,0.9,0.4,1.3,0.7c0.4,0.3,0.7,0.7,1,1.2s0.4,1,0.4,1.7c0,0.1,0,0.2,0,0.2s0,0.1,0,0.2l-0.2,0.1H739.6z M743.9,543.8
			c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.1-0.3,0.1-0.4c0-0.6-0.2-1.1-0.5-1.6c-0.4-0.4-0.9-0.7-1.6-0.7c-0.7,0-1.4,0.3-1.8,0.8
			c-0.5,0.5-0.8,1.2-0.9,2.1L743.9,543.8L743.9,543.8z"
          />
        </g>
      </g>
      <g id="_x39_9_punto" v-bind:class="{'opac':over == 'x39'}">
        <circle class="st6" cx="448.8" cy="209.2" r="25" />
      </g>
      <g id="_x39_9_arrow" v-bind:class="{'opac':over != 'x39'}">
        <g>
          <path
            class="st7"
            d="M444.4,29.6c0.2,1.5,0.6,3.2,0.6,3.2v3.1v2.7l-0.3,4l-0.9,4.9c0,0-1.5,8.6-1.3,9.9s-0.7,10.7-0.7,10.7
			l-0.6,11.3v11l-0.5,11.2v7.7c0,0,0.2,15.3,1.7,26.8s2.3,19.6,2.3,19.6s0.9,13.9,0.4,14.7c-0.6,0.7-1.3-1.8-1.3-1.8l-3.1-9.9
			c0,0-1.5-3.3-1.7-4.2c-0.1-0.9-2.3-8.6-2.3-9s-1.5-7.9-1.5-7.9l-1.6-7.4l-0.6-4.4l-0.7-5.9c0,0-0.9-3.5-0.9-3.7s-0.8-2.4-1-2.7
			c-0.2-0.3-1.4-1.7-1.4-1.7l-1.6-0.5l-0.6,0.9c0,0,0.6,0.7,0.7,0.9s0.7,2.9,0.7,2.9l1.5,6.7l1,6.3l0.8,4l0.9,5.7l0.4,3.4l0.6,3.7
			l1.4,5.7c0,0,1,4.5,1.1,4.7s1.4,3.3,1.4,3.3s1.3,4.2,1.5,4.4s1.8,4.7,1.8,4.7l0.7,1.9c0,0,1.6,4.8,4,5c0,0,0.6,0.6,1.8-3.1
			c1.3-3.7,3.3-7.9,3.5-8c0.2-0.2,3.7-8.7,3.7-8.7s1.3-2.2,1.3-2.5s1-4.2,1.4-4.6s1.5-4.5,1.9-4.9s0.9-2.3,1-2.9s0.4-1.7,0.4-2
			c0-0.2,0-0.7,0-0.7s-0.6-0.2-0.7,0.1s-1.1,2.8-1.1,2.8s-1.4,3.1-1.5,3.3s-2.9,6.6-3.1,6.9s-2.1,3.9-2.1,3.9l-1.3,4.4l-1.4,2.8
			l-1.2,2.1c0,0-0.6,2.2-0.7,1.2s-0.5-3.6-0.5-4s-0.2-8.3-0.2-8.9s-0.7-8.1-0.7-8.5s-0.9-5.8-1-6.3s-0.6-6.3-0.6-6.3
			s-0.7-3.8-0.6-6.7s-1.1-12.7-0.8-13.6c0.3-0.9-0.1-20.9-0.1-20.9l0.6-9.8c0,0,0.7-18.5,0.9-19.6s0.8-7.2,0.9-7.4s0.6-5.6,0.6-5.6
			s1-5.8,1.2-7.1c0.1-1.2,0.8-5.8,0.5-7.9c-0.3-2-1-3.4-1.3-3.7c-0.4-0.3-0.6-0.6-1.2-0.3C444.4,29.6,444.4,29.6,444.4,29.6z"
          />
        </g>
        <g>
          <path
            class="st7"
            d="M476,51.7c1.2-0.3,2.3-0.7,3.3-1.4c1-0.6,1.9-1.3,2.7-2.2s1.4-1.8,1.9-2.9c0.5-1.1,0.8-2.2,0.9-3.4l0,0
			c-0.2,0.4-0.5,0.7-0.7,1c-0.3,0.3-0.6,0.6-0.9,0.8s-0.7,0.4-1.1,0.5s-0.9,0.2-1.4,0.2c-0.7,0-1.3-0.1-1.8-0.4s-0.9-0.6-1.3-1
			c-0.3-0.4-0.6-0.9-0.7-1.4c-0.2-0.5-0.2-1-0.2-1.5s0.1-1.1,0.3-1.7c0.2-0.6,0.5-1.2,0.9-1.7s1-1,1.6-1.4c0.7-0.4,1.5-0.5,2.4-0.5
			s1.7,0.2,2.3,0.5s1.2,0.8,1.6,1.4c0.4,0.6,0.7,1.3,0.9,2c0.2,0.7,0.3,1.5,0.3,2.2c0,1.3-0.2,2.4-0.6,3.5s-1,2-1.6,2.8
			c-0.7,0.8-1.4,1.6-2.2,2.2s-1.6,1.2-2.4,1.6s-1.6,0.7-2.3,1s-1.3,0.3-1.7,0.3L476,51.7z M478.7,39c0,0.5,0.1,0.9,0.2,1.4
			c0.1,0.4,0.3,0.8,0.6,1.2c0.3,0.3,0.6,0.6,1,0.8s0.8,0.3,1.4,0.3c0.4,0,0.8-0.1,1.1-0.2s0.7-0.3,1-0.5s0.5-0.5,0.7-0.8
			s0.3-0.7,0.3-1.1c0-0.6,0-1.2-0.1-1.7c-0.1-0.6-0.3-1.1-0.5-1.5s-0.6-0.8-1-1.1s-0.9-0.4-1.5-0.4c-0.5,0-1,0.1-1.4,0.3
			s-0.7,0.5-0.9,0.8c-0.2,0.3-0.4,0.7-0.5,1.2C478.8,38.1,478.7,38.6,478.7,39z"
          />
          <path
            class="st7"
            d="M489.6,51.7c1.2-0.3,2.3-0.7,3.3-1.4c1-0.6,1.9-1.3,2.7-2.2s1.4-1.8,1.9-2.9c0.5-1.1,0.8-2.2,0.9-3.4l0,0
			c-0.2,0.4-0.5,0.7-0.7,1c-0.3,0.3-0.6,0.6-0.9,0.8s-0.7,0.4-1.1,0.5s-0.9,0.2-1.4,0.2c-0.7,0-1.3-0.1-1.8-0.4s-0.9-0.6-1.3-1
			c-0.3-0.4-0.6-0.9-0.7-1.4c-0.2-0.5-0.2-1-0.2-1.5s0.1-1.1,0.3-1.7c0.2-0.6,0.5-1.2,0.9-1.7s1-1,1.6-1.4c0.7-0.4,1.5-0.5,2.4-0.5
			s1.7,0.2,2.3,0.5s1.2,0.8,1.6,1.4c0.4,0.6,0.7,1.3,0.9,2c0.2,0.7,0.3,1.5,0.3,2.2c0,1.3-0.2,2.4-0.6,3.5s-1,2-1.6,2.8
			c-0.7,0.8-1.4,1.6-2.2,2.2s-1.6,1.2-2.4,1.6s-1.6,0.7-2.3,1s-1.3,0.3-1.7,0.3L489.6,51.7z M492.3,39c0,0.5,0.1,0.9,0.2,1.4
			c0.1,0.4,0.3,0.8,0.6,1.2c0.3,0.3,0.6,0.6,1,0.8s0.8,0.3,1.4,0.3c0.4,0,0.8-0.1,1.1-0.2s0.7-0.3,1-0.5s0.5-0.5,0.7-0.8
			s0.3-0.7,0.3-1.1c0-0.6,0-1.2-0.1-1.7c-0.1-0.6-0.3-1.1-0.5-1.5s-0.6-0.8-1-1.1s-0.9-0.4-1.5-0.4c-0.5,0-1,0.1-1.4,0.3
			s-0.7,0.5-0.9,0.8c-0.2,0.3-0.4,0.7-0.5,1.2C492.4,38.1,492.3,38.6,492.3,39z"
          />
          <path
            class="st7"
            d="M512.6,38.5c0,0.6-0.1,1.2-0.3,1.7s-0.5,1-0.9,1.3c-0.4,0.4-0.8,0.7-1.3,0.9s-1.1,0.3-1.6,0.3
			c-0.6,0-1.1-0.1-1.6-0.3s-0.9-0.5-1.2-0.8c-0.3-0.4-0.6-0.8-0.8-1.2c-0.2-0.5-0.3-1-0.3-1.5c0-0.6,0.1-1.2,0.3-1.7
			c0.2-0.5,0.5-1,0.9-1.3c0.4-0.4,0.8-0.7,1.3-0.9s1.1-0.3,1.7-0.3s1.1,0.1,1.6,0.3s0.9,0.5,1.2,0.8c0.3,0.4,0.6,0.8,0.8,1.2
			C512.5,37.4,512.6,37.9,512.6,38.5z M510.7,38.4c0-0.3,0-0.7-0.1-1c0-0.4-0.1-0.7-0.3-1.1c-0.1-0.3-0.4-0.6-0.6-0.8
			c-0.3-0.2-0.7-0.3-1.1-0.3s-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,0.9c-0.2,0.4-0.3,0.8-0.3,1.2s-0.1,0.8-0.1,1.2
			c0,0.3,0,0.7,0.1,1c0,0.4,0.1,0.7,0.3,1c0.1,0.3,0.4,0.6,0.6,0.8c0.3,0.2,0.7,0.3,1.1,0.3s0.8-0.1,1.1-0.4
			c0.3-0.3,0.5-0.6,0.6-0.9c0.2-0.4,0.3-0.8,0.3-1.2S510.7,38.8,510.7,38.4z M508,52.4l11.1-18h1.1l-11.1,18H508z M524.1,47.9
			c0,0.6-0.1,1.2-0.3,1.7s-0.5,1-0.9,1.3c-0.4,0.4-0.8,0.7-1.3,0.9s-1.1,0.3-1.6,0.3c-0.6,0-1.1-0.1-1.6-0.3s-0.9-0.5-1.2-0.8
			c-0.3-0.4-0.6-0.8-0.8-1.2c-0.2-0.5-0.3-1-0.3-1.5c0-0.6,0.1-1.2,0.3-1.7s0.5-1,0.9-1.3c0.4-0.4,0.8-0.7,1.3-0.9s1.1-0.3,1.6-0.3
			c0.6,0,1.1,0.1,1.6,0.3s0.9,0.5,1.2,0.8c0.3,0.4,0.6,0.8,0.8,1.2C524,46.9,524.1,47.4,524.1,47.9z M522.1,47.9c0-0.3,0-0.7-0.1-1
			c0-0.4-0.1-0.7-0.3-1.1c-0.1-0.3-0.4-0.6-0.6-0.8c-0.3-0.2-0.7-0.3-1.1-0.3c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,0.9
			c-0.2,0.4-0.3,0.8-0.3,1.2s-0.1,0.8-0.1,1.2c0,0.3,0,0.7,0.1,1c0,0.4,0.1,0.7,0.3,1c0.1,0.3,0.4,0.6,0.6,0.8
			c0.3,0.2,0.7,0.3,1.1,0.3c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-0.9c0.2-0.4,0.3-0.8,0.3-1.2
			C522.1,48.6,522.1,48.2,522.1,47.9z"
          />
          <path
            class="st7"
            d="M533.9,51.2h0.7c0.3,0,0.6,0,0.8-0.1s0.3-0.3,0.3-0.7v-6.9c0-0.2,0-0.4-0.1-0.5s-0.2-0.2-0.4-0.3
			c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.3-0.1-0.4-0.1V42c0.9-0.4,1.6-0.7,2.1-1s0.8-0.4,0.9-0.4c0.1,0,0.2,0.1,0.2,0.3v2.2l0,0
			c0.1-0.2,0.3-0.5,0.5-0.8s0.4-0.6,0.7-0.8c0.3-0.3,0.5-0.5,0.9-0.6c0.3-0.2,0.7-0.3,1-0.3s0.6,0.1,0.9,0.3c0.3,0.2,0.5,0.5,0.5,1
			c0,0.3-0.1,0.6-0.3,0.9s-0.5,0.4-0.8,0.4c-0.2,0-0.3,0-0.5-0.1c-0.1-0.1-0.2-0.1-0.3-0.2s-0.2-0.1-0.3-0.2
			c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.5,0.1s-0.4,0.2-0.6,0.4c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,0.3-0.2,0.6-0.2,0.9v5.7
			c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h1.4v0.6c-0.7,0-1.3,0-1.8,0s-1.1,0-1.6,0s-1,0-1.5,0s-1,0-1.4,0L533.9,51.2
			L533.9,51.2z"
          />
          <path
            class="st7"
            d="M545.6,45c0,0.1,0,0.3,0,0.5s0,0.5,0,0.9c0,0.6,0.1,1.1,0.3,1.6s0.5,1,0.8,1.3c0.3,0.4,0.7,0.7,1.1,0.9
			c0.4,0.2,0.9,0.3,1.4,0.3c0.7,0,1.3-0.2,1.8-0.5s1-0.7,1.5-1.2l0.3,0.3c-0.3,0.4-0.6,0.7-0.9,1.1s-0.7,0.6-1,0.9
			c-0.4,0.3-0.8,0.5-1.2,0.6s-0.9,0.2-1.4,0.2c-1,0-1.8-0.2-2.4-0.6c-0.6-0.4-1.1-0.9-1.4-1.5s-0.6-1.2-0.7-1.8
			c-0.1-0.6-0.2-1.1-0.2-1.5c0-1.2,0.2-2.2,0.5-3c0.4-0.8,0.8-1.4,1.3-1.9s1.1-0.8,1.6-1c0.6-0.2,1.1-0.3,1.5-0.3
			c0.5,0,1,0.1,1.5,0.2s0.9,0.4,1.3,0.7c0.4,0.3,0.7,0.7,1,1.2s0.4,1,0.4,1.7c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2l-0.2,0.1h-6.9
			L545.6,45L545.6,45z M549.9,44.3c0.3,0,0.5-0.1,0.6-0.2s0.1-0.3,0.1-0.4c0-0.6-0.2-1.1-0.5-1.6s-0.9-0.7-1.6-0.7
			c-0.7,0-1.4,0.3-1.9,0.8s-0.8,1.2-1,2.1C545.6,44.3,549.9,44.3,549.9,44.3z"
          />
          <path
            class="st7"
            d="M563.6,42.3c0,0.3-0.1,0.5-0.2,0.7c-0.2,0.2-0.4,0.3-0.7,0.3s-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.5-0.7
			s-0.5-0.5-0.8-0.7s-0.8-0.3-1.3-0.3s-1,0.1-1.4,0.4c-0.4,0.3-0.7,0.6-1,1s-0.5,0.9-0.6,1.4s-0.2,1-0.2,1.4c0,0.6,0.1,1.2,0.3,1.8
			s0.5,1.1,0.8,1.5c0.4,0.4,0.8,0.8,1.3,1c0.5,0.3,1.1,0.4,1.8,0.4c0.6,0,1.2-0.1,1.6-0.3s0.9-0.5,1.4-0.8l0.4,0.4
			c-0.6,0.7-1.3,1.3-2.1,1.8s-1.7,0.7-2.7,0.7c-0.6,0-1.2-0.1-1.8-0.4c-0.6-0.3-1.1-0.7-1.6-1.1s-0.8-1-1.1-1.6s-0.4-1.2-0.4-1.9
			c0-0.9,0.2-1.7,0.5-2.5s0.7-1.5,1.3-2.1c0.5-0.6,1.2-1.1,1.9-1.4c0.7-0.3,1.6-0.5,2.4-0.5c0.3,0,0.7,0,1,0.1
			c0.4,0.1,0.7,0.2,1.1,0.3c0.3,0.1,0.6,0.3,0.8,0.5C563.5,41.7,563.6,42,563.6,42.3z"
          />
          <path
            class="st7"
            d="M564.9,41c0.5,0,0.9,0,1.4,0s0.9,0,1.4,0c0.4,0,0.7,0,1.1,0c0.4,0,0.7,0,1.1,0v0.6c-0.1,0-0.2,0-0.4,0
			s-0.3,0-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0.1,0.3,0.2,0.7c0.1,0.3,0.2,0.7,0.4,1.1
			c0.2,0.4,0.4,0.9,0.6,1.4c0.2,0.5,0.4,1,0.6,1.4c0.2,0.4,0.3,0.9,0.5,1.2c0.1,0.4,0.2,0.6,0.3,0.8l0,0c0.1-0.2,0.2-0.5,0.4-0.9
			s0.3-0.8,0.5-1.2s0.4-0.8,0.5-1.3c0.2-0.4,0.4-0.9,0.5-1.2c0.1-0.4,0.3-0.7,0.4-1s0.1-0.5,0.1-0.6c0-0.3,0-0.5-0.1-0.6
			s-0.2-0.2-0.3-0.3s-0.3-0.1-0.4-0.1s-0.3,0-0.5,0V41c0.4,0,0.8,0,1.1,0c0.4,0,0.8,0,1.1,0s0.6,0,0.9,0c0.3,0,0.6,0,0.9,0v0.6
			c-0.6,0-1,0.1-1.2,0.4c-0.3,0.3-0.5,0.7-0.7,1.1l-5.5,13c-0.2,0.4-0.4,0.7-0.6,0.9c-0.3,0.2-0.6,0.3-0.9,0.3
			c-0.4,0-0.7-0.1-0.9-0.3s-0.3-0.5-0.3-0.8s0.1-0.6,0.3-0.8c0.2-0.3,0.5-0.4,0.8-0.4c0.2,0,0.4,0,0.5,0.1c0.1,0,0.3,0.1,0.5,0.3
			c0.6-1.3,1.2-2.6,1.7-3.9c-0.3-0.8-0.6-1.6-0.9-2.2c-0.2-0.6-0.5-1.3-0.8-2.1c-0.3-0.7-0.6-1.4-0.8-2.1c-0.3-0.7-0.5-1.2-0.7-1.7
			s-0.3-0.8-0.4-0.9c-0.2-0.3-0.4-0.5-0.6-0.7c-0.2-0.2-0.5-0.2-0.9-0.2L564.9,41L564.9,41z"
          />
          <path
            class="st7"
            d="M586.7,42.3c0,0.3-0.1,0.5-0.2,0.7c-0.2,0.2-0.4,0.3-0.7,0.3s-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.5-0.7
			s-0.5-0.5-0.8-0.7s-0.8-0.3-1.3-0.3s-1,0.1-1.4,0.4c-0.4,0.3-0.7,0.6-1,1s-0.5,0.9-0.6,1.4s-0.2,1-0.2,1.4c0,0.6,0.1,1.2,0.3,1.8
			s0.5,1.1,0.8,1.5c0.4,0.4,0.8,0.8,1.3,1c0.5,0.3,1.1,0.4,1.8,0.4c0.6,0,1.2-0.1,1.6-0.3s0.9-0.5,1.4-0.8l0.4,0.4
			c-0.6,0.7-1.3,1.3-2.1,1.8s-1.7,0.7-2.7,0.7c-0.6,0-1.2-0.1-1.8-0.4c-0.6-0.3-1.1-0.7-1.6-1.1s-0.8-1-1.1-1.6s-0.4-1.2-0.4-1.9
			c0-0.9,0.2-1.7,0.5-2.5s0.7-1.5,1.3-2.1c0.5-0.6,1.2-1.1,1.9-1.4c0.7-0.3,1.6-0.5,2.4-0.5c0.3,0,0.7,0,1,0.1
			c0.4,0.1,0.7,0.2,1.1,0.3c0.3,0.1,0.6,0.3,0.8,0.5C586.6,41.7,586.7,42,586.7,42.3z"
          />
          <path
            class="st7"
            d="M588.4,51.2h0.5c0.3,0,0.6,0,0.8-0.1s0.3-0.3,0.3-0.7V36.7c0-0.4,0-0.7-0.1-0.9c0-0.2-0.1-0.4-0.2-0.5
			s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.2v-0.4c0.5-0.2,1-0.3,1.4-0.5c0.5-0.2,0.8-0.4,1.1-0.5c0.1-0.1,0.2-0.1,0.3-0.2
			c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.3v16.9c0,0.4,0.1,0.6,0.4,0.7c0.2,0.1,0.5,0.1,0.9,0.1h0.4v0.6c-0.4,0-0.8,0-1.3,0
			c-0.4,0-0.9,0-1.3,0c-0.4,0-0.9,0-1.3,0s-0.9,0-1.3,0L588.4,51.2L588.4,51.2z"
          />
          <path
            class="st7"
            d="M597.2,45c0,0.1,0,0.3,0,0.5s0,0.5,0,0.9c0,0.6,0.1,1.1,0.3,1.6s0.5,1,0.8,1.3c0.3,0.4,0.7,0.7,1.1,0.9
			c0.4,0.2,0.9,0.3,1.4,0.3c0.7,0,1.3-0.2,1.8-0.5s1-0.7,1.5-1.2l0.3,0.3c-0.3,0.4-0.6,0.7-0.9,1.1s-0.7,0.6-1,0.9
			c-0.4,0.3-0.8,0.5-1.2,0.6s-0.9,0.2-1.4,0.2c-1,0-1.8-0.2-2.4-0.6c-0.6-0.4-1.1-0.9-1.4-1.5s-0.6-1.2-0.7-1.8
			c-0.1-0.6-0.2-1.1-0.2-1.5c0-1.2,0.2-2.2,0.5-3c0.4-0.8,0.8-1.4,1.3-1.9s1.1-0.8,1.6-1c0.6-0.2,1.1-0.3,1.5-0.3
			c0.5,0,1,0.1,1.5,0.2s0.9,0.4,1.3,0.7c0.4,0.3,0.7,0.7,1,1.2s0.4,1,0.4,1.7c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2l-0.2,0.1h-6.9
			L597.2,45L597.2,45z M601.5,44.3c0.3,0,0.5-0.1,0.6-0.2s0.1-0.3,0.1-0.4c0-0.6-0.2-1.1-0.5-1.6s-0.9-0.7-1.6-0.7
			c-0.7,0-1.4,0.3-1.9,0.8s-0.8,1.2-1,2.1C597.2,44.3,601.5,44.3,601.5,44.3z"
          />
          <path
            class="st7"
            d="M616.6,49.7c0,0.2,0,0.4,0,0.6c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.4,0.1s0.4,0,0.6,0h0.4v0.5l0,0
			c-0.2,0-0.4,0.1-0.7,0.2c-0.3,0.1-0.6,0.2-1,0.3s-0.7,0.2-1,0.3s-0.5,0.1-0.5,0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.3,0-0.4v-1.1
			l0,0c-0.4,0.5-1,0.9-1.6,1.2c-0.7,0.4-1.4,0.5-2.1,0.5c-0.7,0-1.4-0.1-1.9-0.4s-1-0.7-1.4-1.2s-0.7-1.1-0.9-1.7
			c-0.2-0.6-0.3-1.3-0.3-2c0-0.8,0.2-1.6,0.5-2.4c0.3-0.7,0.7-1.4,1.3-2c0.5-0.6,1.2-1,1.9-1.4c0.7-0.3,1.5-0.5,2.4-0.5
			c0.8,0,1.6,0.1,2.3,0.4v-4.3c0-0.4,0-0.7-0.1-0.9c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.2v-0.4
			c0.5-0.2,1-0.3,1.4-0.5c0.5-0.2,0.8-0.4,1.1-0.5c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.3v16.4
			L616.6,49.7L616.6,49.7z M614.6,43.6c0-0.5-0.1-0.8-0.3-1.1s-0.4-0.5-0.6-0.7c-0.3-0.2-0.5-0.3-0.8-0.4c-0.3-0.1-0.6-0.1-0.9-0.1
			c-0.4,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.8,0.4-1.2,0.8s-0.6,0.8-0.8,1.4c-0.2,0.6-0.3,1.3-0.3,2.1c0,0.7,0.1,1.3,0.3,1.9
			c0.2,0.6,0.5,1.1,0.8,1.5s0.7,0.7,1.2,1c0.5,0.2,1,0.4,1.5,0.4c0.2,0,0.4,0,0.7-0.1s0.5-0.2,0.8-0.3s0.5-0.3,0.6-0.5
			c0.2-0.2,0.3-0.4,0.3-0.6V43.6z"
          />
          <path
            class="st7"
            d="M486.3,80.2c-0.3,0.3-0.7,0.6-1.1,0.9s-0.8,0.4-1.3,0.4s-0.9-0.2-1.1-0.6s-0.4-0.8-0.4-1.2
			c-0.6,0.5-1.1,1-1.7,1.3s-1.3,0.5-2,0.5c-0.8,0-1.4-0.2-1.9-0.7s-0.7-1-0.7-1.7c0-0.5,0.1-0.9,0.3-1.2s0.5-0.6,1-0.9
			s1.1-0.6,1.9-0.9s1.9-0.7,3.2-1.1v-1.6c0-1.4-0.8-2.1-2.4-2.1c-0.5,0-1,0.1-1.3,0.4s-0.5,0.7-0.5,1.3c0,0.3-0.2,0.6-0.5,0.8
			c-0.3,0.2-0.6,0.3-0.9,0.3c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.2-0.1-0.3c0-0.3,0.1-0.6,0.2-0.9c0.1-0.2,0.3-0.5,0.6-0.7
			c0.2-0.2,0.5-0.4,0.9-0.6s0.7-0.4,1.2-0.6c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.3-0.2,0.5-0.2c0.2-0.1,0.3-0.1,0.5-0.2
			c0.2,0,0.4-0.1,0.6-0.1c0.3,0,0.6,0,0.9,0.1s0.7,0.2,1,0.4c0.3,0.2,0.6,0.4,0.8,0.6c0.2,0.3,0.3,0.5,0.3,0.9v6.8
			c0,0.5,0.1,0.9,0.2,1.1c0.2,0.2,0.4,0.3,0.6,0.3s0.3,0,0.5-0.1s0.3-0.2,0.4-0.3L486.3,80.2z M482.3,75.7c-0.3,0.1-0.7,0.3-1.2,0.4
			s-1,0.3-1.5,0.5s-0.9,0.5-1.2,0.8s-0.5,0.7-0.5,1.1c0,0.5,0.2,0.9,0.5,1.2s0.7,0.5,1.2,0.5c0.6,0,1.1-0.1,1.5-0.4s0.8-0.6,1.2-1
			V75.7z"
          />
          <path
            class="st7"
            d="M487.9,80.5h0.5c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7V66.1c0-0.4,0-0.7-0.1-0.9
			c0-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.2v-0.4c0.5-0.2,1-0.3,1.4-0.5c0.5-0.2,0.8-0.4,1.1-0.5
			c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.3v16.9c0,0.4,0.1,0.6,0.4,0.7c0.2,0.1,0.5,0.1,0.9,0.1h0.4
			v0.6c-0.4,0-0.8,0-1.3,0c-0.4,0-0.9,0-1.3,0s-0.9,0-1.3,0s-0.9,0-1.3,0V80.5z"
          />
          <path
            class="st7"
            d="M505.2,79.8c0,0.2,0.1,0.4,0.2,0.4s0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.5,0s0.3,0,0.5,0v0.5
			c-0.3,0-0.6,0.1-0.9,0.1c-0.4,0.1-0.7,0.1-1.1,0.2s-0.7,0.2-0.9,0.2c-0.3,0.1-0.5,0.1-0.6,0.2l-0.1-0.1v-2
			c-0.7,0.6-1.4,1.1-2.1,1.5c-0.6,0.4-1.4,0.6-2.3,0.6c-0.3,0-0.7-0.1-1-0.2s-0.6-0.3-0.9-0.5s-0.5-0.5-0.6-0.9s-0.2-0.8-0.2-1.3
			v-6.3c0-0.2,0-0.3,0-0.5s-0.1-0.3-0.2-0.4s-0.2-0.2-0.4-0.3c-0.2-0.1-0.5-0.2-0.8-0.2v-0.5c0.2,0,0.5,0,0.8,0s0.7,0,1-0.1
			c0.3,0,0.6,0,0.9,0s0.4,0,0.5,0s0.2,0,0.2,0.1s0,0.3,0,0.6v6.3c0,0.2,0,0.4,0,0.7c0,0.3,0.1,0.6,0.2,0.9s0.3,0.6,0.6,0.8
			c0.3,0.2,0.7,0.3,1.2,0.3c0.4,0,0.9-0.1,1.4-0.4s1-0.6,1.6-1.1v-6.6c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			s-0.4-0.2-0.7-0.2c-0.2-0.1-0.5-0.1-0.7-0.2v-0.4c1.1-0.1,2-0.2,2.6-0.2c0.7-0.1,1-0.1,1.2-0.1c0.1,0,0.2,0,0.2,0.1s0,0.2,0,0.3
			L505.2,79.8L505.2,79.8z"
          />
          <path
            class="st7"
            d="M509.8,73.1c0-0.3,0-0.5-0.1-0.6c0-0.2-0.1-0.3-0.2-0.4s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.2-0.6-0.3V71
			c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.1,0.6-0.2,0.8-0.3c0.2-0.1,0.5-0.2,0.6-0.2c0.2-0.1,0.3-0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.1
			s0.2,0.1,0.2,0.3v1.5c0.3-0.2,0.7-0.5,1-0.7s0.6-0.4,1-0.6c0.3-0.2,0.7-0.3,1.1-0.4c0.4-0.1,0.8-0.1,1.2-0.1
			c0.7,0,1.2,0.2,1.6,0.5c0.4,0.3,0.8,0.8,0.9,1.4c0.3-0.2,0.6-0.4,1-0.7c0.3-0.2,0.6-0.4,1-0.6s0.7-0.3,1.1-0.4
			c0.4-0.1,0.8-0.2,1.2-0.2c0.5,0,1,0.1,1.4,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1.1s0.2,0.8,0.2,1.3v6.3
			c0,0.4,0.1,0.6,0.4,0.7c0.2,0.1,0.5,0.1,0.9,0.1h0.4v0.6c-0.4,0-0.8,0-1.2,0s-0.9,0-1.3,0s-0.9,0-1.3,0s-0.9,0-1.3,0v-0.6h0.4
			c0.3,0,0.6,0,0.8-0.1s0.3-0.3,0.3-0.7v-5.4c0-0.4,0-0.8-0.1-1.1c0-0.4-0.2-0.7-0.3-1c-0.2-0.3-0.4-0.5-0.6-0.7
			c-0.3-0.2-0.6-0.3-1.1-0.3c-0.2,0-0.5,0-0.8,0.1s-0.5,0.2-0.8,0.3c-0.3,0.1-0.5,0.2-0.7,0.4s-0.4,0.2-0.6,0.3v7.2
			c0,0.4,0.1,0.6,0.4,0.7c0.2,0.1,0.5,0.1,0.9,0.1h0.4V81c-0.4,0-0.9,0-1.3,0c-0.4,0-0.9,0-1.3,0s-0.9,0-1.3,0s-0.9,0-1.3,0v-0.6
			h0.4c0.3,0,0.6,0,0.8-0.1s0.3-0.3,0.3-0.7v-5.4c0-0.4,0-0.8-0.1-1.1c0-0.4-0.2-0.7-0.3-1c-0.2-0.3-0.4-0.5-0.6-0.7
			c-0.3-0.2-0.6-0.3-1.1-0.3c-0.2,0-0.5,0-0.8,0.1s-0.5,0.2-0.8,0.3c-0.3,0.1-0.5,0.2-0.7,0.4c-0.2,0.1-0.4,0.2-0.6,0.3v7.2
			c0,0.4,0.1,0.6,0.4,0.7c0.2,0.1,0.5,0.1,0.9,0.1h0.4v0.6c-0.4,0-0.9,0-1.3,0c-0.4,0-0.9,0-1.3,0s-0.9,0-1.3,0s-0.9,0-1.4,0v-0.6
			h0.5c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-6.3C509.9,73.1,509.8,73.1,509.8,73.1z"
          />
          <path
            class="st7"
            d="M528.6,80.5h0.5c0.3,0,0.6,0,0.8-0.1s0.3-0.3,0.3-0.7v-6.6c0-0.3,0-0.5-0.1-0.6c0-0.2-0.1-0.3-0.2-0.4
			c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.2-0.6-0.3V71c0.3-0.1,0.6-0.2,0.9-0.3s0.6-0.2,0.8-0.3c0.3-0.1,0.5-0.2,0.6-0.2
			c0.2-0.1,0.3-0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2,0.1,0.2,0.3v9.4c0,0.4,0.1,0.6,0.4,0.7c0.2,0.1,0.5,0.1,0.9,0.1h0.4
			v0.6c-0.4,0-0.8,0-1.3,0c-0.4,0-0.9,0-1.3,0c-0.4,0-0.9,0-1.3,0s-0.9,0-1.3,0L528.6,80.5L528.6,80.5z M532.4,65
			c0,0.3-0.1,0.6-0.4,0.9c-0.2,0.2-0.5,0.4-0.8,0.4s-0.6-0.1-0.8-0.4c-0.2-0.2-0.4-0.5-0.4-0.9c0-0.3,0.1-0.6,0.4-0.8
			c0.2-0.2,0.5-0.4,0.8-0.4s0.6,0.1,0.8,0.3C532.3,64.4,532.4,64.6,532.4,65z"
          />
          <path
            class="st7"
            d="M536.9,73.2c0-0.2,0-0.4-0.1-0.6c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.2-0.7-0.3
			v-0.5c0.5-0.2,1-0.4,1.6-0.5c0.5-0.2,1.1-0.4,1.7-0.6c0.1,0,0.1,0.1,0.1,0.2v1.6c0.7-0.5,1.4-0.9,2-1.3c0.7-0.4,1.4-0.5,2.2-0.5
			c0.5,0,1,0.1,1.3,0.3s0.6,0.4,0.9,0.7c0.2,0.3,0.4,0.7,0.5,1.1s0.1,0.9,0.1,1.3v6.3c0,0.4,0.1,0.6,0.4,0.7
			c0.2,0.1,0.5,0.1,0.9,0.1h0.4v0.6c-0.4,0-0.9,0-1.3,0c-0.4,0-0.9,0-1.3,0s-0.9,0-1.3,0c-0.4,0-0.9,0-1.3,0v-0.6h0.4
			c0.3,0,0.6,0,0.8-0.1s0.3-0.3,0.3-0.7v-6.3c0-0.7-0.2-1.2-0.6-1.6c-0.4-0.3-0.9-0.5-1.6-0.5c-0.2,0-0.4,0-0.7,0.1
			s-0.5,0.2-0.7,0.3c-0.3,0.1-0.5,0.2-0.8,0.4c-0.3,0.1-0.5,0.3-0.7,0.4v7.2c0,0.4,0.1,0.6,0.4,0.7c0.2,0.1,0.5,0.1,0.9,0.1h0.4v0.6
			c-0.4,0-0.9,0-1.3,0c-0.4,0-0.9,0-1.3,0s-0.9,0-1.3,0s-0.9,0-1.3,0v-0.6h0.4c0.3,0,0.6,0,0.8-0.1s0.3-0.3,0.3-0.7L536.9,73.2
			L536.9,73.2z"
          />
          <path
            class="st7"
            d="M559.5,79.8c0,0.2,0.1,0.4,0.2,0.4c0.1,0,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.5,0s0.3,0,0.5,0v0.5
			c-0.3,0-0.6,0.1-0.9,0.1c-0.4,0.1-0.7,0.1-1.1,0.2s-0.7,0.2-0.9,0.2c-0.3,0.1-0.5,0.1-0.6,0.2l-0.1-0.1v-2
			c-0.7,0.6-1.4,1.1-2.1,1.5c-0.6,0.4-1.4,0.6-2.3,0.6c-0.3,0-0.7-0.1-1-0.2s-0.6-0.3-0.9-0.5c-0.3-0.2-0.5-0.5-0.6-0.9
			s-0.2-0.8-0.2-1.3v-6.3c0-0.2,0-0.3,0-0.5s-0.1-0.3-0.2-0.4s-0.2-0.2-0.4-0.3c-0.2-0.1-0.5-0.2-0.8-0.2v-0.5c0.2,0,0.5,0,0.8,0
			s0.7,0,1-0.1c0.3,0,0.6,0,0.9,0c0.3,0,0.4,0,0.5,0s0.2,0,0.2,0.1s0,0.3,0,0.6v6.3c0,0.2,0,0.4,0,0.7c0,0.3,0.1,0.6,0.2,0.9
			s0.3,0.6,0.6,0.8c0.3,0.2,0.7,0.3,1.2,0.3c0.4,0,0.9-0.1,1.4-0.4s1-0.6,1.6-1.1v-6.6c0-0.1-0.1-0.2-0.2-0.3
			c-0.1-0.1-0.3-0.2-0.5-0.3s-0.4-0.2-0.7-0.2c-0.2-0.1-0.5-0.1-0.7-0.2v-0.4c1.1-0.1,2-0.2,2.6-0.2c0.7-0.1,1-0.1,1.2-0.1
			c0.1,0,0.2,0,0.2,0.1s0,0.2,0,0.3L559.5,79.8L559.5,79.8z"
          />
          <path
            class="st7"
            d="M564.1,73.1c0-0.3,0-0.5-0.1-0.6c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.2-0.6-0.3
			V71c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.1,0.6-0.2,0.8-0.3c0.2-0.1,0.5-0.2,0.6-0.2c0.2-0.1,0.3-0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.1
			c0.1,0,0.2,0.1,0.2,0.3v1.5c0.3-0.2,0.7-0.5,1-0.7s0.6-0.4,1-0.6c0.3-0.2,0.7-0.3,1.1-0.4c0.4-0.1,0.8-0.1,1.2-0.1
			c0.7,0,1.2,0.2,1.6,0.5c0.4,0.3,0.8,0.8,0.9,1.4c0.3-0.2,0.6-0.4,1-0.7c0.3-0.2,0.6-0.4,1-0.6s0.7-0.3,1.1-0.4
			c0.4-0.1,0.8-0.2,1.2-0.2c0.5,0,1,0.1,1.4,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1.1s0.2,0.8,0.2,1.3v6.3
			c0,0.4,0.1,0.6,0.4,0.7c0.2,0.1,0.5,0.1,0.9,0.1h0.4v0.6c-0.4,0-0.8,0-1.2,0s-0.9,0-1.3,0s-0.9,0-1.3,0s-0.9,0-1.3,0v-0.6h0.4
			c0.3,0,0.6,0,0.8-0.1s0.3-0.3,0.3-0.7v-5.4c0-0.4,0-0.8-0.1-1.1c0-0.4-0.2-0.7-0.3-1c-0.2-0.3-0.4-0.5-0.6-0.7
			c-0.3-0.2-0.6-0.3-1.1-0.3c-0.2,0-0.5,0-0.8,0.1s-0.5,0.2-0.8,0.3c-0.3,0.1-0.5,0.2-0.7,0.4s-0.4,0.2-0.6,0.3v7.2
			c0,0.4,0.1,0.6,0.4,0.7c0.2,0.1,0.5,0.1,0.9,0.1h0.4V81c-0.4,0-0.9,0-1.3,0c-0.4,0-0.9,0-1.3,0s-0.9,0-1.3,0s-0.9,0-1.3,0v-0.6
			h0.4c0.3,0,0.6,0,0.8-0.1s0.3-0.3,0.3-0.7v-5.4c0-0.4,0-0.8-0.1-1.1c0-0.4-0.2-0.7-0.3-1c-0.2-0.3-0.4-0.5-0.6-0.7
			c-0.3-0.2-0.6-0.3-1.1-0.3c-0.2,0-0.5,0-0.8,0.1s-0.5,0.2-0.8,0.3c-0.3,0.1-0.5,0.2-0.7,0.4c-0.2,0.1-0.4,0.2-0.6,0.3v7.2
			c0,0.4,0.1,0.6,0.4,0.7c0.2,0.1,0.5,0.1,0.9,0.1h0.4v0.6c-0.4,0-0.9,0-1.3,0c-0.4,0-0.9,0-1.3,0s-0.9,0-1.3,0c-0.4,0-0.9,0-1.4,0
			v-0.6h0.5c0.3,0,0.6,0,0.8-0.1s0.3-0.3,0.3-0.7v-6.3C564.2,73.1,564.1,73.1,564.1,73.1z"
          />
          <path
            class="st7"
            d="M589.5,80.5h0.7c0.3,0,0.6,0,0.8-0.1s0.3-0.3,0.3-0.7v-6.9c0-0.2,0-0.4-0.1-0.5s-0.2-0.2-0.4-0.3
			c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.3-0.1-0.4-0.1v-0.4c0.9-0.4,1.6-0.7,2.1-1s0.8-0.4,0.9-0.4c0.1,0,0.2,0.1,0.2,0.3v2.2l0,0
			c0.1-0.2,0.3-0.5,0.5-0.8s0.4-0.6,0.7-0.8c0.3-0.3,0.5-0.5,0.9-0.6c0.3-0.2,0.7-0.3,1-0.3s0.6,0.1,0.9,0.3c0.3,0.2,0.5,0.5,0.5,1
			c0,0.3-0.1,0.6-0.3,0.9s-0.5,0.4-0.8,0.4c-0.2,0-0.3,0-0.5-0.1c-0.1-0.1-0.2-0.1-0.3-0.2s-0.2-0.1-0.3-0.2s-0.3-0.1-0.5-0.1
			c-0.1,0-0.2,0-0.5,0.1s-0.4,0.2-0.6,0.4c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,0.3-0.2,0.6-0.2,0.9v5.7c0,0.4,0.1,0.6,0.3,0.7
			s0.5,0.1,0.8,0.1h1.4V81c-0.7,0-1.3,0-1.8,0s-1.1,0-1.6,0s-1,0-1.5,0s-1,0-1.4,0L589.5,80.5L589.5,80.5z"
          />
          <path
            class="st7"
            d="M599.5,75.8c0-0.8,0.1-1.5,0.4-2.2c0.3-0.7,0.7-1.3,1.2-1.9c0.5-0.5,1.1-0.9,1.8-1.3c0.7-0.3,1.4-0.5,2.2-0.5
			s1.6,0.1,2.4,0.4s1.4,0.7,1.9,1.1c0.5,0.5,0.9,1.1,1.2,1.8c0.3,0.7,0.5,1.5,0.5,2.4c0,0.8-0.1,1.5-0.4,2.2
			c-0.3,0.7-0.7,1.3-1.2,1.9c-0.5,0.5-1.1,0.9-1.8,1.3c-0.7,0.3-1.4,0.5-2.2,0.5s-1.6-0.1-2.3-0.4s-1.3-0.7-1.9-1.1s-1-1.1-1.3-1.8
			C599.6,77.4,599.5,76.6,599.5,75.8z M601.7,75.5c0,0.5,0.1,1.1,0.2,1.7c0.1,0.6,0.4,1.2,0.6,1.7c0.3,0.5,0.7,1,1.1,1.4
			s1,0.5,1.6,0.5s1.1-0.2,1.6-0.5c0.4-0.3,0.8-0.7,1.1-1.2s0.5-1,0.6-1.6s0.2-1.1,0.2-1.6s-0.1-1.1-0.2-1.7
			c-0.1-0.6-0.4-1.2-0.6-1.7c-0.3-0.5-0.7-1-1.1-1.4s-1-0.5-1.7-0.5c-0.6,0-1.1,0.2-1.6,0.5c-0.4,0.3-0.8,0.7-1.1,1.2
			s-0.5,1-0.6,1.6C601.8,74.5,601.7,75,601.7,75.5z"
          />
          <path
            class="st7"
            d="M613.1,75.8c0-0.8,0.1-1.5,0.4-2.2c0.3-0.7,0.7-1.3,1.2-1.9c0.5-0.5,1.1-0.9,1.8-1.3c0.7-0.3,1.4-0.5,2.2-0.5
			s1.6,0.1,2.4,0.4s1.4,0.7,1.9,1.1c0.5,0.5,0.9,1.1,1.2,1.8c0.3,0.7,0.5,1.5,0.5,2.4c0,0.8-0.1,1.5-0.4,2.2
			c-0.3,0.7-0.7,1.3-1.2,1.9c-0.5,0.5-1.1,0.9-1.8,1.3c-0.7,0.3-1.4,0.5-2.2,0.5s-1.6-0.1-2.3-0.4s-1.3-0.7-1.9-1.1s-1-1.1-1.3-1.8
			C613.2,77.4,613.1,76.6,613.1,75.8z M615.3,75.5c0,0.5,0.1,1.1,0.2,1.7c0.1,0.6,0.4,1.2,0.6,1.7c0.3,0.5,0.7,1,1.1,1.4
			s1,0.5,1.6,0.5s1.1-0.2,1.6-0.5c0.4-0.3,0.8-0.7,1.1-1.2s0.5-1,0.6-1.6s0.2-1.1,0.2-1.6s-0.1-1.1-0.2-1.7
			c-0.1-0.6-0.4-1.2-0.6-1.7c-0.3-0.5-0.7-1-1.1-1.4s-1-0.5-1.7-0.5c-0.6,0-1.1,0.2-1.6,0.5c-0.4,0.3-0.8,0.7-1.1,1.2
			s-0.5,1-0.6,1.6C615.4,74.5,615.3,75,615.3,75.5z"
          />
          <path
            class="st7"
            d="M633.4,70.3v1.3h-3.1v8.3c0,0.3,0.1,0.5,0.4,0.5c0.2,0,0.5,0.1,0.8,0.1h1.1v0.6c-0.5,0-1,0-1.6,0s-1,0-1.6,0
			c-0.5,0-1,0-1.5,0s-1,0-1.5,0v-0.6h0.9c0.5,0,0.7-0.1,0.9-0.3c0.1-0.2,0.2-0.3,0.2-0.5v-8.1h-1.7v-0.4c0.1-0.1,0.3-0.2,0.5-0.3
			c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.3-0.2,0.5-0.4c0.1-0.2,0.2-0.3,0.2-0.6c0-0.4,0-0.8,0.1-1.3s0.2-1,0.3-1.5s0.3-1,0.6-1.5
			s0.6-0.9,0.9-1.3c0.7-0.7,1.3-1.2,1.9-1.4c0.6-0.2,0.9-0.3,1.1-0.3c0.1,0,0.2,0,0.3,0s0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.3
			s0.1,0.3,0.1,0.5c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.3-0.1s-0.2-0.1-0.3-0.1
			s-0.3,0-0.5,0c-0.4,0-0.7,0.1-0.9,0.3c-0.2,0.2-0.4,0.4-0.5,0.7c-0.1,0.3-0.2,0.6-0.2,0.9s0,0.6,0,0.9v3.5H633.4z"
          />
        </g>
      </g>
      <g id="tesla_punto" v-bind:class="{'opac':over == 'tesla'}">
        <circle class="st6" cx="61.3" cy="349.6" r="25" />
      </g>
      <g id="tesla_arrow" v-bind:class="{'opac':over != 'tesla'}">
        <g>
          <path
            class="st7"
            d="M167,109.1c0-0.2,0-0.4,0-0.7c-0.6-0.1-1.2-0.3-1.9-0.3c-2.3,0.1-4.6,0.2-6.9,0.3c-6.1,0.2-12.2,0.7-18.1,2
			c-1,0.2-1.9,0.5-2.8,0.7c-4.6,0.9-9.1,1.9-13.7,2.8c-3.6,0.7-7.2,1.7-10.9,1.8c-6.2,0.1-12.2,0.7-18.1,3.1
			c-3.4,1.5-7,2.6-10.1,4.8c-7.2,5.2-13.4,11.3-18,19.1c-4.7,7.9-7.4,16.6-9.6,25.4c-1.5,5.8-2.6,11.8-3.5,17.7
			c-1,6.8-2.2,13.6-2.4,20.4c-0.3,8.3,0.3,16.6,0.5,24.9c0.3,8.5,0.6,17,1,25.4c0.3,7.6,0.6,15.2,1,22.7c0.4,6.9,0.9,13.7,1.3,20.6
			c0.1,1.3-0.2,2.4-1.6,2.9c-1.4,0.5-1.8-0.8-2.2-1.5c-4-6.1-7.9-12.2-11.8-18.3c-0.5-0.8-1-1.6-2.1-1c-1,0.6-1,1.4-0.4,2.2
			c1.7,2.9,3.4,5.7,5.2,8.6c0.8,1.3,1.8,2.5,2.6,3.8c2.3,3.4,4.6,6.9,6.9,10.3c0.9,1.3,3,1.8,4.3,1.2c1.7-0.8,1.4-2.1,1.2-3.5
			c-0.1-0.9,0.1-1.9,0.4-2.7c0.6-1.5,1.5-2.8,2-4.4c1.8-6,4.5-11.6,7.6-17c1.1-2,2.3-3.9,3.4-5.9c0.2-0.4,0.1-0.9,0.1-1.4
			c-0.4,0.2-0.9,0.3-1.2,0.6c-1.2,1.5-2.5,3-3.4,4.7c-2.3,4.3-4.4,8.6-6.6,12.9c-0.8,1.6-1.4,3.2-2.1,4.8c-0.5-0.8-0.8-1.4-0.8-2
			c-0.4-4.5-0.7-9.1-1-13.6c-0.4-5.4-0.7-10.9-1-16.3c-0.3-6.6-0.5-13.2-0.7-19.8c-0.1-3.3-0.2-6.6-0.3-9.8
			c-0.2-7.2-0.6-14.4-0.6-21.6c0-3.7,0.5-7.5,0.6-11.2c0.2-7.8,1.7-15.4,3.1-23c1-5.4,2.3-10.9,3.8-16.2c1.9-7,4.5-13.6,8.2-19.9
			c4.5-7.6,10.7-13.4,17.9-18.2c2.4-1.6,5.5-2.2,8.1-3.5c5.3-2.5,10.9-3.3,16.6-3.3c2.9,0,5.8-0.4,8.7-1c3.2-0.6,6.2-1.7,9.4-2.5
			c2.6-0.6,5.2-0.9,7.8-1.3c5.5-0.8,10.9-1.8,16.4-2.4c3.5-0.4,7-0.5,10.5-0.7C165.1,109.6,166,109.3,167,109.1z"
          />
          <path
            class="st7"
            d="M167,109.1c-1,0.2-1.9,0.6-2.9,0.6c-3.5,0.3-7,0.3-10.5,0.7c-5.5,0.7-11,1.6-16.4,2.4
			c-2.6,0.4-5.2,0.7-7.8,1.3c-3.1,0.7-6.2,1.8-9.4,2.5c-2.8,0.6-5.8,1-8.7,1c-5.8,0.1-11.4,0.9-16.6,3.3c-2.7,1.3-5.7,1.9-8.1,3.5
			c-7.2,4.8-13.4,10.6-17.9,18.2c-3.7,6.3-6.3,13-8.2,19.9c-1.5,5.3-2.7,10.8-3.8,16.2c-1.4,7.6-2.9,15.2-3.1,23
			c-0.1,3.7-0.6,7.5-0.6,11.2c0.1,7.2,0.4,14.4,0.6,21.6c0.1,3.3,0.2,6.6,0.3,9.8c0.2,6.6,0.4,13.2,0.7,19.8
			c0.2,5.4,0.6,10.9,1,16.3c0.3,4.6,0.6,9.1,1,13.6c0.1,0.6,0.3,1.3,0.8,2c0.7-1.6,1.3-3.3,2.1-4.8c2.2-4.3,4.3-8.7,6.6-12.9
			c0.9-1.7,2.2-3.1,3.4-4.7c0.2-0.3,0.8-0.4,1.2-0.6c0,0.5,0.1,1-0.1,1.4c-1.1,2-2.3,3.9-3.4,5.9c-3.1,5.4-5.8,11-7.6,17
			c-0.5,1.5-1.4,2.9-2,4.4c-0.3,0.8-0.6,1.8-0.4,2.7c0.2,1.4,0.5,2.8-1.2,3.5c-1.3,0.6-3.4,0.1-4.3-1.2c-2.4-3.4-4.6-6.8-6.9-10.3
			c-0.9-1.3-1.8-2.5-2.6-3.8c-1.8-2.8-3.5-5.7-5.2-8.6c-0.5-0.9-0.6-1.7,0.4-2.2c1.1-0.6,1.5,0.2,2.1,1c3.9,6.1,7.8,12.2,11.8,18.3
			c0.5,0.7,0.9,2,2.2,1.5c1.4-0.5,1.7-1.6,1.6-2.9c-0.5-6.9-1-13.7-1.3-20.6c-0.4-7.6-0.7-15.2-1-22.7c-0.3-8.5-0.7-16.9-1-25.4
			c-0.3-8.3-0.8-16.6-0.5-24.9c0.2-6.8,1.4-13.7,2.4-20.4c0.9-6,2-11.9,3.5-17.7c2.2-8.8,4.9-17.4,9.6-25.4
			c4.6-7.8,10.8-13.9,18-19.1c3.1-2.2,6.6-3.3,10.1-4.8c5.8-2.5,11.8-3,18.1-3.1c3.6-0.1,7.3-1.1,10.9-1.8c4.6-0.9,9.1-1.8,13.7-2.8
			c1-0.2,1.9-0.5,2.8-0.7c6-1.3,12-1.8,18.1-2c2.3-0.1,4.6-0.2,6.9-0.3c0.6,0,1.2,0.2,1.9,0.3C167,108.6,167,108.9,167,109.1z"
          />
        </g>
        <g>
          <path
            class="st7"
            d="M208.5,101h-0.7c0-0.3,0-0.6-0.1-0.9c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.6-0.5
			c-0.3-0.1-0.6-0.2-1.1-0.2h-3.7v13.7c0,0.4,0.1,0.8,0.2,1c0.1,0.2,0.3,0.4,0.5,0.5s0.4,0.2,0.7,0.2s0.6,0,0.9,0v0.6
			c-0.6,0-1.1,0-1.7,0c-0.5,0-1.1,0-1.6,0c-0.6,0-1.2,0-1.7,0s-1.2,0-1.8,0v-0.6c0.3,0,0.6,0,0.9,0s0.5-0.1,0.7-0.2s0.4-0.3,0.5-0.5
			c0.1-0.2,0.2-0.6,0.2-1V98.8h-3.2c-0.6,0-1,0-1.3,0.1s-0.6,0.2-0.7,0.4c-0.2,0.2-0.3,0.4-0.4,0.7c-0.1,0.3-0.2,0.6-0.3,1h-0.6
			l0.3-3.9h0.5c0.1,0.3,0.3,0.5,0.6,0.6s0.6,0.1,0.9,0.1h11.1c0.4,0,0.8-0.1,1.1-0.2s0.5-0.3,0.5-0.5h0.5L208.5,101z"
          />
          <path
            class="st7"
            d="M209.8,108.1c0,0.1,0,0.3,0,0.5c0,0.2,0,0.5,0,0.9c0,0.6,0.1,1.1,0.3,1.6s0.5,1,0.8,1.3
			c0.3,0.4,0.7,0.7,1.1,0.9c0.4,0.2,0.9,0.3,1.4,0.3c0.7,0,1.3-0.2,1.8-0.5c0.5-0.3,1-0.7,1.5-1.2l0.3,0.3c-0.3,0.4-0.6,0.7-0.9,1.1
			c-0.3,0.4-0.7,0.6-1,0.9c-0.4,0.3-0.8,0.5-1.2,0.6s-0.9,0.2-1.4,0.2c-1,0-1.8-0.2-2.4-0.6c-0.6-0.4-1.1-0.9-1.4-1.5
			s-0.6-1.2-0.7-1.8c-0.1-0.6-0.2-1.1-0.2-1.5c0-1.2,0.2-2.2,0.5-3c0.4-0.8,0.8-1.4,1.3-1.9s1.1-0.8,1.6-1c0.6-0.2,1.1-0.3,1.5-0.3
			c0.5,0,1,0.1,1.5,0.2c0.5,0.1,0.9,0.4,1.3,0.7c0.4,0.3,0.7,0.7,1,1.2s0.4,1,0.4,1.7c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2l-0.2,0.5
			H209.8z M214.1,107.4c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.1-0.3,0.1-0.4c0-0.6-0.2-1.1-0.5-1.6s-0.9-0.7-1.6-0.7
			c-0.7,0-1.4,0.3-1.9,0.8s-0.8,1.2-1,2.1C209.8,107.4,214.1,107.4,214.1,107.4z"
          />
          <path
            class="st7"
            d="M219.3,111.5c0.1,0.4,0.2,0.8,0.4,1.1c0.2,0.4,0.4,0.7,0.6,0.9c0.3,0.3,0.6,0.5,0.9,0.6
			c0.4,0.2,0.7,0.2,1.2,0.2c0.4,0,0.7,0,0.9-0.1c0.3-0.1,0.5-0.2,0.6-0.4c0.1-0.2,0.3-0.4,0.3-0.6c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.5-0.1-0.9-0.4-1.2c-0.3-0.3-0.6-0.6-1-0.8c-0.4-0.2-0.8-0.4-1.3-0.6c-0.5-0.2-0.9-0.4-1.3-0.7c-0.4-0.3-0.7-0.6-1-1
			s-0.4-0.9-0.4-1.6c0-0.3,0.1-0.7,0.2-1.1c0.1-0.4,0.4-0.7,0.7-1c0.3-0.3,0.7-0.6,1.2-0.8c0.5-0.2,1.1-0.3,1.7-0.3
			c0.4,0,0.8,0.1,1.3,0.2c0.5,0.1,1,0.3,1.5,0.5l0.2,2.5h-0.6c-0.1-0.8-0.4-1.4-0.8-1.8c-0.4-0.4-1-0.6-1.8-0.6
			c-0.5,0-0.9,0.1-1.3,0.4c-0.4,0.3-0.6,0.7-0.6,1.2s0.1,0.9,0.4,1.1c0.3,0.3,0.6,0.5,1.1,0.8c0.4,0.2,0.9,0.4,1.4,0.6
			c0.5,0.2,0.9,0.4,1.4,0.7c0.4,0.3,0.8,0.6,1.1,1s0.4,0.9,0.4,1.6c0,0.4-0.1,0.8-0.2,1.2c-0.2,0.4-0.4,0.7-0.8,1.1
			c-0.4,0.4-0.8,0.6-1.3,0.8c-0.5,0.2-1.2,0.3-1.9,0.3c-0.5,0-1-0.1-1.5-0.2c-0.5-0.2-1-0.4-1.4-0.6l-0.3-2.8L219.3,111.5z"
          />
          <path
            class="st7"
            d="M228.2,114.3h0.5c0.3,0,0.6,0,0.8-0.1s0.3-0.3,0.3-0.7V99.8c0-0.4,0-0.7-0.1-0.9c0-0.2-0.1-0.4-0.2-0.5
			s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.2v-0.4c0.5-0.2,1-0.3,1.4-0.5c0.5-0.2,0.8-0.4,1.1-0.5c0.1-0.1,0.2-0.1,0.3-0.2
			c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.3v16.9c0,0.4,0.1,0.6,0.4,0.7c0.2,0.1,0.5,0.1,0.9,0.1h0.4v0.6c-0.4,0-0.8,0-1.3,0
			c-0.4,0-0.9,0-1.3,0c-0.4,0-0.9,0-1.3,0s-0.9,0-1.3,0L228.2,114.3L228.2,114.3z"
          />
          <path
            class="st7"
            d="M245.6,113.9c-0.3,0.3-0.7,0.6-1.1,0.9s-0.8,0.4-1.3,0.4s-0.9-0.2-1.1-0.6c-0.2-0.4-0.4-0.8-0.4-1.2
			c-0.6,0.5-1.1,1-1.7,1.3c-0.6,0.3-1.3,0.5-2,0.5c-0.8,0-1.4-0.2-1.9-0.7s-0.7-1-0.7-1.7c0-0.5,0.1-0.9,0.3-1.2
			c0.2-0.3,0.5-0.6,1-0.9c0.5-0.3,1.1-0.6,1.9-0.9c0.8-0.3,1.9-0.7,3.2-1.1v-1.6c0-1.4-0.8-2.1-2.4-2.1c-0.5,0-1,0.1-1.3,0.4
			s-0.5,0.7-0.5,1.3c0,0.3-0.2,0.6-0.5,0.8c-0.3,0.2-0.6,0.3-0.9,0.3c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.2-0.1-0.3
			c0-0.3,0.1-0.6,0.2-0.9c0.1-0.2,0.3-0.5,0.6-0.7c0.2-0.2,0.5-0.4,0.9-0.6s0.7-0.4,1.2-0.6c0.2-0.1,0.4-0.2,0.6-0.3
			c0.2-0.1,0.3-0.2,0.5-0.2c0.2-0.1,0.3-0.1,0.5-0.2c0.2,0,0.4-0.1,0.6-0.1c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.7,0.2,1,0.4
			c0.3,0.2,0.6,0.4,0.8,0.6c0.2,0.3,0.3,0.5,0.3,0.9v6.8c0,0.5,0.1,0.9,0.2,1.1c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.3,0,0.5-0.1
			s0.3-0.2,0.4-0.3L245.6,113.9z M241.6,109.5c-0.3,0.1-0.7,0.3-1.2,0.4s-1,0.3-1.5,0.5c-0.5,0.2-0.9,0.5-1.2,0.8
			c-0.3,0.3-0.5,0.7-0.5,1.1c0,0.5,0.2,0.9,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.6,0,1.1-0.1,1.5-0.4s0.8-0.6,1.2-1V109.5z"
          />
          <path
            class="st7"
            d="M197.2,132.8c0.2-0.2,0.5-0.4,0.8-0.6s0.6-0.4,0.9-0.6c0.3-0.2,0.6-0.3,1-0.4c0.4-0.1,0.7-0.2,1-0.2
			c0.7,0,1.3,0.1,1.9,0.4c0.6,0.3,1,0.7,1.4,1.1c0.4,0.4,0.7,1,0.9,1.6c0.2,0.6,0.3,1.3,0.3,1.9c0,0.8-0.1,1.6-0.4,2.3
			c-0.3,0.8-0.7,1.5-1.2,2.1c-0.5,0.6-1.1,1.1-1.8,1.5c-0.7,0.4-1.5,0.5-2.4,0.5c-0.5,0-0.9,0-1.2-0.1c-0.4-0.1-0.7-0.1-0.9-0.2
			c-0.3-0.1-0.5-0.2-0.7-0.3c-0.2-0.1-0.4-0.2-0.6-0.2l-0.7,0.6l-0.5-0.3c0.1-0.7,0.2-1.5,0.3-2.2s0.1-1.4,0.1-2.2v-10.3
			c0-0.4,0-0.7-0.1-0.9c0-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.2v-0.4c0.5-0.2,1-0.3,1.4-0.5
			c0.5-0.2,0.8-0.4,1.1-0.5c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.3v8.9H197.2z M197.2,139.5
			c0,0.2,0.1,0.5,0.2,0.8s0.3,0.6,0.5,0.8c0.2,0.3,0.5,0.5,0.8,0.6c0.3,0.2,0.7,0.3,1.1,0.3c0.7,0,1.2-0.1,1.7-0.4s0.9-0.7,1.2-1.1
			c0.3-0.5,0.5-1,0.7-1.5c0.1-0.6,0.2-1.1,0.2-1.6c0-0.7-0.1-1.3-0.3-1.9c-0.2-0.6-0.5-1.1-0.9-1.5c-0.4-0.4-0.8-0.8-1.3-1.1
			s-0.9-0.4-1.4-0.4s-0.9,0.1-1.3,0.4c-0.4,0.2-0.7,0.5-1.1,0.8L197.2,139.5L197.2,139.5z"
          />
          <path
            class="st7"
            d="M217.8,141.3c-0.3,0.3-0.7,0.6-1.1,0.9s-0.8,0.4-1.3,0.4s-0.9-0.2-1.1-0.6c-0.2-0.4-0.4-0.8-0.4-1.2
			c-0.6,0.5-1.1,1-1.7,1.3c-0.6,0.3-1.3,0.5-2,0.5c-0.8,0-1.4-0.2-1.9-0.7s-0.7-1-0.7-1.7c0-0.5,0.1-0.9,0.3-1.2
			c0.2-0.3,0.5-0.6,1-0.9s1.1-0.6,1.9-0.9c0.8-0.3,1.9-0.7,3.2-1.1v-1.6c0-1.4-0.8-2.1-2.4-2.1c-0.5,0-1,0.1-1.3,0.4
			s-0.5,0.7-0.5,1.3c0,0.3-0.2,0.6-0.5,0.8s-0.6,0.3-0.9,0.3c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.2-0.1-0.3
			c0-0.3,0.1-0.6,0.2-0.9c0.1-0.2,0.3-0.5,0.6-0.7c0.2-0.2,0.5-0.4,0.9-0.6s0.7-0.4,1.2-0.6c0.2-0.1,0.4-0.2,0.6-0.3
			c0.2-0.1,0.3-0.2,0.5-0.2c0.2-0.1,0.3-0.1,0.5-0.2c0.2,0,0.4-0.1,0.6-0.1c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.7,0.2,1,0.4
			s0.6,0.4,0.8,0.6c0.2,0.3,0.3,0.5,0.3,0.9v6.8c0,0.5,0.1,0.9,0.2,1.1c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.3,0,0.5-0.1
			c0.2-0.1,0.3-0.2,0.4-0.3L217.8,141.3z M213.9,136.8c-0.3,0.1-0.7,0.3-1.2,0.4c-0.5,0.1-1,0.3-1.5,0.5s-0.9,0.5-1.2,0.8
			s-0.5,0.7-0.5,1.1c0,0.5,0.2,0.9,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.6,0,1.1-0.1,1.5-0.4s0.8-0.6,1.2-1V136.8z"
          />
          <path
            class="st7"
            d="M222.7,139.2c0,0.4,0,0.7,0.1,1s0.2,0.5,0.4,0.6c0.2,0.1,0.3,0.3,0.5,0.3c0.2,0.1,0.4,0.1,0.5,0.1
			c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.8-0.5l0.4,0.4c-0.4,0.4-0.9,0.8-1.4,1.2c-0.5,0.3-1.1,0.5-1.8,0.5
			c-0.8,0-1.4-0.2-1.7-0.6c-0.4-0.4-0.6-1-0.6-1.7v-7.6h-1.6v-0.4c0.3-0.2,0.6-0.5,0.9-0.7c0.3-0.2,0.5-0.4,0.8-0.7
			c0.3-0.2,0.5-0.5,0.8-0.9c0.3-0.3,0.5-0.7,0.9-1.2h0.3v2.5h3.6v1.3h-3.8V139.2z"
          />
          <path
            class="st7"
            d="M230.8,139.2c0,0.4,0,0.7,0.1,1s0.2,0.5,0.4,0.6c0.2,0.1,0.3,0.3,0.5,0.3c0.2,0.1,0.4,0.1,0.5,0.1
			c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.8-0.5l0.4,0.4c-0.4,0.4-0.9,0.8-1.4,1.2c-0.5,0.3-1.1,0.5-1.8,0.5
			c-0.8,0-1.4-0.2-1.7-0.6c-0.4-0.4-0.6-1-0.6-1.7v-7.6h-1.6v-0.4c0.3-0.2,0.6-0.5,0.9-0.7c0.3-0.2,0.5-0.4,0.8-0.7
			c0.3-0.2,0.5-0.5,0.8-0.9c0.3-0.3,0.5-0.7,0.9-1.2h0.3v2.5h3.6v1.3H231v6.6L230.8,139.2L230.8,139.2z"
          />
          <path
            class="st7"
            d="M237.7,135.5c0,0.1,0,0.3,0,0.5s0,0.5,0,0.9c0,0.6,0.1,1.1,0.3,1.6s0.5,1,0.8,1.3c0.3,0.4,0.7,0.7,1.1,0.9
			c0.4,0.2,0.9,0.3,1.4,0.3c0.7,0,1.3-0.2,1.8-0.5s1-0.7,1.5-1.2l0.3,0.3c-0.3,0.4-0.6,0.7-0.9,1.1c-0.3,0.4-0.7,0.6-1,0.9
			c-0.4,0.3-0.8,0.5-1.2,0.6c-0.4,0.1-0.9,0.2-1.4,0.2c-1,0-1.8-0.2-2.4-0.6c-0.6-0.4-1.1-0.9-1.4-1.5c-0.3-0.6-0.6-1.2-0.7-1.8
			c-0.1-0.6-0.2-1.1-0.2-1.5c0-1.2,0.2-2.2,0.5-3c0.4-0.8,0.8-1.4,1.3-1.9s1.1-0.8,1.6-1c0.6-0.2,1.1-0.3,1.5-0.3
			c0.5,0,1,0.1,1.5,0.2c0.5,0.1,0.9,0.4,1.3,0.7c0.4,0.3,0.7,0.7,1,1.2s0.4,1,0.4,1.7c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2l-0.2,0.1
			h-6.9V135.5z M242.1,134.7c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.1-0.3,0.1-0.4c0-0.6-0.2-1.1-0.5-1.6s-0.9-0.7-1.6-0.7
			c-0.7,0-1.4,0.3-1.9,0.8s-0.8,1.2-1,2.1C237.8,134.7,242.1,134.7,242.1,134.7z"
          />
          <path
            class="st7"
            d="M246.4,141.6h0.7c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-6.9c0-0.2,0-0.4-0.1-0.5
			c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.3-0.1-0.4-0.1v-0.4c0.9-0.4,1.6-0.7,2.1-1s0.8-0.4,0.9-0.4
			c0.1,0,0.2,0.1,0.2,0.3v2.2l0,0c0.1-0.2,0.3-0.5,0.5-0.8s0.4-0.6,0.7-0.8c0.3-0.3,0.5-0.5,0.9-0.6c0.3-0.2,0.7-0.3,1-0.3
			s0.6,0.1,0.9,0.3c0.3,0.2,0.5,0.5,0.5,1c0,0.3-0.1,0.6-0.3,0.9s-0.5,0.4-0.8,0.4c-0.2,0-0.3,0-0.5-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
			c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.5,0.1c-0.3,0.1-0.4,0.2-0.6,0.4c-0.2,0.2-0.4,0.4-0.5,0.6
			c-0.1,0.3-0.2,0.6-0.2,0.9v5.7c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h1.4v0.6c-0.7,0-1.3,0-1.8,0s-1.1,0-1.6,0
			s-1,0-1.5,0s-1,0-1.4,0v-0.5H246.4z"
          />
          <path
            class="st7"
            d="M256.1,141.6h0.5c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-6.6c0-0.3,0-0.5-0.1-0.6
			c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.2-0.6-0.3v-0.5c0.3-0.1,0.6-0.2,0.9-0.3s0.6-0.2,0.8-0.3
			c0.3-0.1,0.5-0.2,0.6-0.2c0.2-0.1,0.3-0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.1s0.2,0.1,0.2,0.3v9.4c0,0.4,0.1,0.6,0.4,0.7
			c0.2,0.1,0.5,0.1,0.9,0.1h0.4v0.6c-0.4,0-0.8,0-1.3,0c-0.4,0-0.9,0-1.3,0c-0.4,0-0.9,0-1.3,0c-0.4,0-0.9,0-1.3,0L256.1,141.6
			L256.1,141.6z M259.9,126.1c0,0.3-0.1,0.6-0.4,0.9c-0.2,0.2-0.5,0.4-0.8,0.4s-0.6-0.1-0.8-0.4c-0.2-0.2-0.4-0.5-0.4-0.9
			c0-0.3,0.1-0.6,0.4-0.8c0.2-0.2,0.5-0.4,0.8-0.4c0.3,0,0.6,0.1,0.8,0.3C259.8,125.5,259.9,125.7,259.9,126.1z"
          />
          <path
            class="st7"
            d="M264.9,135.5c0,0.1,0,0.3,0,0.5s0,0.5,0,0.9c0,0.6,0.1,1.1,0.3,1.6c0.2,0.5,0.5,1,0.8,1.3
			c0.3,0.4,0.7,0.7,1.1,0.9c0.4,0.2,0.9,0.3,1.4,0.3c0.7,0,1.3-0.2,1.8-0.5s1-0.7,1.5-1.2l0.3,0.3c-0.3,0.4-0.6,0.7-0.9,1.1
			c-0.3,0.4-0.7,0.6-1,0.9c-0.4,0.3-0.8,0.5-1.2,0.6c-0.4,0.1-0.9,0.2-1.4,0.2c-1,0-1.8-0.2-2.4-0.6c-0.6-0.4-1.1-0.9-1.4-1.5
			c-0.3-0.6-0.6-1.2-0.7-1.8s-0.2-1.1-0.2-1.5c0-1.2,0.2-2.2,0.5-3c0.4-0.8,0.8-1.4,1.3-1.9s1.1-0.8,1.6-1c0.6-0.2,1.1-0.3,1.5-0.3
			c0.5,0,1,0.1,1.5,0.2c0.5,0.1,0.9,0.4,1.3,0.7c0.4,0.3,0.7,0.7,1,1.2s0.4,1,0.4,1.7c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2l-0.2,0.1
			h-6.9L264.9,135.5L264.9,135.5z M269.2,134.7c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.1-0.3,0.1-0.4c0-0.6-0.2-1.1-0.5-1.6
			s-0.9-0.7-1.6-0.7s-1.4,0.3-1.9,0.8s-0.8,1.2-1,2.1C264.9,134.7,269.2,134.7,269.2,134.7z"
          />
          <path
            class="st7"
            d="M274.4,138.8c0.1,0.4,0.2,0.8,0.4,1.1c0.2,0.4,0.4,0.7,0.6,0.9c0.3,0.3,0.6,0.5,0.9,0.6
			c0.4,0.2,0.7,0.2,1.2,0.2c0.4,0,0.7,0,0.9-0.1c0.3-0.1,0.5-0.2,0.6-0.4c0.1-0.2,0.3-0.4,0.3-0.6c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.5-0.1-0.9-0.4-1.2s-0.6-0.6-1-0.8c-0.4-0.2-0.8-0.4-1.3-0.6s-0.9-0.4-1.3-0.7c-0.4-0.3-0.7-0.6-1-1c-0.3-0.4-0.4-0.9-0.4-1.6
			c0-0.3,0.1-0.7,0.2-1.1c0.1-0.4,0.4-0.7,0.7-1s0.7-0.6,1.2-0.8s1.1-0.3,1.7-0.3c0.4,0,0.8,0.1,1.3,0.2c0.5,0.1,1,0.3,1.5,0.5
			l0.2,2.5h-0.6c-0.1-0.8-0.4-1.4-0.8-1.8c-0.4-0.4-1-0.6-1.8-0.6c-0.5,0-0.9,0.1-1.3,0.4s-0.6,0.7-0.6,1.2s0.1,0.9,0.4,1.1
			c0.3,0.3,0.6,0.5,1.1,0.8c0.4,0.2,0.9,0.4,1.4,0.6s0.9,0.4,1.4,0.7c0.4,0.3,0.8,0.6,1.1,1c0.3,0.4,0.4,0.9,0.4,1.6
			c0,0.4-0.1,0.8-0.2,1.2c-0.2,0.4-0.4,0.7-0.8,1.1c-0.4,0.4-0.8,0.6-1.3,0.8c-0.5,0.2-1.2,0.3-1.9,0.3c-0.5,0-1-0.1-1.5-0.2
			c-0.5-0.2-1-0.4-1.4-0.6l-0.3-2.8L274.4,138.8z"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<style type="text/css">
	.st0{fill:#D8D800;}
	.st1{fill:#D82900;}
	.st2{fill:#00D8BE;}
	.st3{fill:#C800D8;}
	.st4{fill:#1A00D8;}
	.st5{fill:#30DB00;}
	.st6{fill:none;stroke:#006FDF;stroke-width:4;stroke-miterlimit:10;}
	.st7{fill:#006FDF;}

/* .st0 {
  fill: #006fdf;
}
.st1 {
  fill: #dd0074;
}
.st2 {
  fill: #30db00;
}
*/
.st8, .st9, .st10, .st11, .st12, .st13 {
  fill: transparent;
}
/* .st6 {
  fill: transparent;
  stroke: #0060df;
  stroke-width: 4;
  stroke-miterlimit: 10;
} */
/* .st7 {
  fill: #006fdf;
} */
#board_arrow,
#board_punto,
#solar_punto,
#solar_arrow,
#fsc_punto,
#fsc_arrow,
#raised_punto,
#raised_arrow,
#_x39_9_punto,
#_x39_9_arrow,
#tesla_punto,
#tesla_arrow {
  transition: 1s ease opacity;
}
.opac {
  opacity: 0;
}
/* 
.st0{fill:#D8D800;}
			.st1{fill:#D82900;}
			.st2{fill:#00D8BE;}
			.st3{fill:#C800D8;}
			.st4{fill:#1A00D8;}
			.st5{fill:#30DB00;}
			.st6{fill:none;stroke:#FFFFFF;stroke-width:4;stroke-miterlimit:10;}
			.st7{fill:#006FDF;} */
</style>

<script>
export default {
  data() {
    return {
      over: 'x39',
      accoya: false,
      raised: false,
      recycled: false,
    };
  },
};
</script>